.best-voucher {
	.best-voucher-container {
		color: #e30613;
		padding: 10px 15px;
		overflow: hidden;
		border-bottom: 1px solid #fff;
	}
	.best-voucher-price {
		display: inline-block;
		font-weight: bold;
	}
	.best-voucher-code {
		display: inline-block;
		float: right;
	}
	.best-voucher-code {
		span {
			font-weight: bold;
		}
	}
}
.tooltip-voucher {
	.best-voucher-price {
		font-size: 16px;
		span {
			color: #e30613;
			font-weight: bold;
		}
	}
	.best-voucher-code {
		font-size: 16px;
		span {
			color: #e30613;
			font-weight: bold;
		}
	}
	.best-voucher-message {
		margin-top: 15px;
	}
}