$button-fifth-background			: #e7e7e7;																	//button fifth
$button-fifth-color					: #333333;																	//kolor button fifth
$button-fifth-background-hover		: #f2f2f2;																	//button fifth hover
$button-fifth-color-hover			: #333333;																	//kolor button fifth hover

$button-sixth-background			: #ececec;																	//button sixth
$button-sixth-color					: #666666;																	//kolor button sixth
$button-sixth-background-hover		: #d5d5d5;																	//button sixth hover
$button-sixth-color-hover			: #666666;																	//kolor button sixth hover

$button-seventh-background			: #ebebeb;																	//button fourth
$button-seventh-color				: #333333;																	//kolor button fourth
$button-seventh-background-hover	: #d4d4d4;																	//button fourth hover
$button-seventh-color-hover			: #333333;																	//kolor button fourth hover

$button-eighth-background			: #eb5b50;																	//button eighth
$button-eighth-color				: #ffffff;																	//kolor button eighth
$button-eighth-background-hover		: #d9281a;																	//button eighth hover
$button-eighth-color-hover			: #ffffff;																	//kolor button eighth hover

$button-ninth-background			: #0d8636;																	//button ninth
$button-ninth-color					: #ffffff;																	//kolor button ninth
$button-ninth-background-hover		: #12ba4a;																	//button ninth hover
$button-ninth-color-hover			: #ffffff;																	//kolor button ninth hover

$button-tenth-background			: #b20f10;																	//button tenth
$button-tenth-color					: #ffffff;																	//kolor button tenth
$button-tenth-background-hover		: #f51314;																	//button tenth hover
$button-tenth-color-hover			: #ffffff;																	//kolor button tenth hover

%btn-fifth {
	background: $button-fifth-background;
	color: $button-fifth-color;
	&:hover{
	background: $button-fifth-background-hover;
	color:$button-fifth-color-hover;
	}
}

%btn-sixth {
	background: $button-sixth-background;
	color: $button-sixth-color;
	&:hover{
	background: $button-sixth-background-hover;
	color:$button-sixth-color-hover;
	}
}

%btn-seventh {
	background: $button-seventh-background;
	color: $button-seventh-color;
	&:hover{
	background: $button-seventh-background-hover;
	color:$button-seventh-color-hover;
	}
}

%btn-eighth {
	background: $button-eighth-background;
	color: $button-eighth-color;
	&:hover{
	background: $button-eighth-background-hover;
	color:$button-eighth-color-hover;
	}
}

%btn-ninth {
	background: $button-ninth-background;
	color: $button-ninth-color;
	&:hover{
	background: $button-ninth-background-hover;
	color:$button-ninth-color-hover;
	}
}

%btn-tenth {
	background: $button-tenth-background;
	color: $button-tenth-color;
	&:hover{
	background: $button-tenth-background-hover;
	color:$button-tenth-color-hover;
	}
}

@mixin btn-dimensions($width, $height:"") {
	box-sizing: border-box;
	width: $width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@if ($height!="") {
	padding-top: 0;
	padding-bottom: 0;
	height: $height;
	line-height: $height;
	}
}

@mixin btn-arrow-right() {
	&:after {
	content: "";
	display: inline-block;
	margin-top: 2px;
	margin-left: 5px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5.5px 0 5.5px 5px;
	border-color: transparent transparent transparent #1c1c1b;
	}
}

.btn-fifth{
	@extend %btn-fifth;
}

.btn-sixth{
	@extend %btn-sixth;
}

.btn-seventh{
	@extend %btn-seventh;
}

.btn-eighth{
	@extend %btn-eighth;
}

.btn-ninth{
	@extend %btn-ninth;
}

.btn-tenth{
	@extend %btn-tenth;
}

.powiadomMnieOPytaniu {
	margin-top: 10px;
	@extend %btn-small;
}

.zadajPierwszePytanie {
	margin-top: 10px;
	@extend %btn-small;
}

.useful {
	.voteNo,
	.voteYes {
	@extend %btn-ultrathin;
	}
}

#opinionList .user .zadaj-pytanie {margin: -3px 0 3px;float:left;}
.zadaj-pytanie {
	@extend %btn-small;
}

#addOpinionButton2 .add-opinion {
	margin:0 auto;
	display: block;
}

.addToCartBig {
	@extend %btn-medium;
	display: block;
	margin: 0 auto;
	width: 100px;
}

.powiadomOPytaniach {
	float: right;
	margin-right: 26px;
	@extend %btn-small;
}

.zadajPytanie {
	float: right;
	@extend %btn-small;
}

.odpowiedz {
	@include btn-dimensions(84px, 29px);
	@extend %btn-small;
}

.skomentuj {
	@extend %btn-small;
}

a.yellow-zaloguj {
	@extend %btn-medium;
}

a.register-button {
	@extend %btn-medium;
}

a.wstecz {
	float: left;
	@extend %btn-small;
}

#cart {
	.wstecz {margin:13px 4px 0 10px;}
	.delivery-time {
	.wstecz {margin:17px 4px 0 10px;}
	}
	.zmienSposobDostawy {
	margin:15px 10px 0;
	float:left;
	}

	.wstecz + .zmienSposobDostawy {
	margin:18px 10px 0;
	}
}
a.account-remove{
	@extend %btn;
	@extend %btn-small;
	@extend %btn-fourth;
}
a.wyslij {
	@extend %btn-medium;
}

.wyslij-newsletter {
	@include btn-dimensions(53px, 27px);
	@extend %btn-small;
}

a.porownaj {
	@extend %btn-small;
}

#stores-list .autoSelect {
	margin-left: 4px;
}

#stores-list a.zatwierdz {
	@extend %btn-small;
	@include btn-dimensions(82px);
	margin: 0 4px;
}

#stores-list .rightShop {
	float:right;
}

a.btn-back {
	@extend %btn-small;
}

a.dalej	{
	@extend %btn-medium;
	@include btn-dimensions(85px);
	width:auto;
	min-width:85px;
}

a.powrot-do-sklepu	{
	@extend %btn-small;
}

a.rezerwuje {
	float:right;
	display:block;
	margin:10px 10px 5px;
	@extend %btn-medium;

	@extend %btn;
	@extend %btn-second;
}

a.szukaj2 {
	@extend %btn-small;
}

a.usun {
	@extend %btn-small;
}

a.zapisz {
	display: block;
	@extend %btn-medium;
	@include btn-dimensions(84px);
}

a.zapisz2 {
	@extend %btn-small;
}

a.zmien {
	@extend %btn-small;
}

a.ok{
	@include btn-dimensions(84px);
	@extend %btn-medium;
}

#fancybox-content a.ok {
	left: 0;
	right: 0;
	display: block;
}

a.potwierdzam {
	@include btn-dimensions(136px);
	@extend %btn-medium;
}

a.dalej2 {
	@extend %btn-medium;
}

#left-column #cartForm a.dalej2 {
	@extend %btn-medium;
	@include btn-dimensions(134px);
	margin: 10px auto 0;
	display: block;
}

#left-column #cartForm a.powrot-do-sklepu	{
	@extend %btn-small;
	@include btn-dimensions(134px);
	margin: 10px auto 0;
	display: block;
}

span.rollin {
	@include btn-dimensions(60px);
	@extend %btn-small;
}

span.rollout {
	@include btn-dimensions(60px);
	@extend %btn-small;
}

.pokazWybraneArtykuly{
	@extend %btn-small;
}

#newsletter-box .wyslij{
	@extend %btn-medium;
	margin-bottom: 7px;
}

.zamowDoDomu {
	margin-top:12px;
	margin-right:15px;
	@extend %btn-medium;
}

#compare-products-view .zamowDoDomu {
	margin-right: 0;
	@include btn-dimensions(165px, 31px);
}

.ServicesDelivery .zamowDoDomu {
	float: right;
}

a.wyslij2 {
	@extend %btn-small;
}

a.szybkaRezerwacja {
	@extend %btn-medium;
	@include btn-dimensions(165px, 31px);
}

.addToCart {
	@extend %btn-big;
}

a.przegladaj {
	@extend %btn-small;
}

a.yellow-szukaj {
	@include btn-dimensions(84px, 20px);
}

a.szczegolyZamowienia {
	@extend %btn-medium;
}

.accountBoxLarge.orderHistory a.szczegolyZamowienia.smallButton {
	margin: 0;
	float: right;
	@extend %btn-small;
	@include btn-dimensions(183px, 20px);
}

@mixin btn-duplex($fontSize:"") {
	padding-top: 5px;
	padding-bottom: 3px;

	&>span {
	display: block;
	margin: 3px 0;

	@if ($fontSize!="") {
		font-size: $fontSize;
	} @else {
		font-size: 9px;
	}
	}
}

.zamawiam, a.zamawiam {
	margin:15px 10px 5px;
	float:right;

	@extend %btn-medium;
	@include btn-dimensions(156px);
	@include btn-duplex();

	@extend %btn;
	@extend %btn-second
}

.zamawiamZObowiazkiem {
	margin:10px 10px 5px;
	float:right;

	@include btn-dimensions(300px);

	@extend %btn-second;
	@extend %btn;
}

a.tak {
	@extend %btn-medium;
	@include btn-dimensions(84px, 31px);
}
a.nie {
	@extend %btn-medium;
	@include btn-dimensions(84px, 31px);
}

a.edytujWybraneUslugi {
	float:right;
	@extend %btn-small;
}

a.cancel {
	@extend %btn-small;
}

a.zamowBezUslug {
	@extend %btn-small;
}

a.podgladWniosku {
	@extend %btn-medium;
}

a.ustalTerminBig {
	@extend %btn-medium;
}

a.anulujZamowienieBig {
	@extend %btn-medium;
}

a.zmienFormePlatnosciBig {
	@extend %btn-medium;
}

a.platnoscBig {
	@extend %btn-medium;
	margin-top: 5px;
}

a.anuluj {
	@extend %btn-medium;
	@include btn-dimensions(84px, 31px);
}

a.bezWniesienia {
	@extend %btn-small;
}

a.pobierz {
	@extend %btn-small;
}

a.anuluj2 {
	@extend %btn-small;
	@include btn-dimensions(70px, 20px);
}

a.ustalTermin {
	margin-bottom:5px;
	@extend %btn-small;
	@include btn-dimensions(70px, 20px);
}

a.podglad {
	margin-bottom:5px;
	@extend %btn-small;
	@include btn-dimensions(70px, 20px);
}

a.drukuj {
	margin-bottom:5px;
	@extend %btn-small;
	@include btn-dimensions(70px, 20px);
}

a.zamknij {
	@extend %btn-small;
	@include btn-dimensions(61px, 20px);
}

a.szczegoly {
	margin-bottom:5px;
	@extend %btn-small;
	@include btn-dimensions(70px, 20px);
}

a.przelicz {
	position: absolute;
	left:-14px;
	top:85px;
	margin: 10px 10px 5px 0;
	z-index:10;
	@extend %btn-small;
	@include btn-dimensions(64px, 20px);
}

a.placeEcard {
	margin:10px 10px 5px 0;
	float:right;
	@extend %btn-medium;
	@extend %btn;
	@extend %btn-second;
	@include btn-dimensions(90px, 31px);
}

.fastOrders {
	@extend %btn-medium;
	@include btn-dimensions(156px, 31px);
}

a.powrot {
	float:left;
	@extend %btn-medium;
	@include btn-dimensions(84px, 27px);
}

a.dalej3 {
	display:block;
	margin:0 auto;
	@extend %btn-medium;
	@include btn-dimensions(90px, 31px);
}

a.btn-szukaj {
	@extend %btn-medium;
	@include btn-dimensions(91px, 31px);
}

a.nie-dziekuje {
	@extend %btn-small;
	@include btn-dimensions(100px, 20px);
}

#fancybox-content a.nie-dziekuje {
	margin: 0 auto;
	display: block;
}

a.add-opinion {
	@extend %btn-small;
	@include btn-dimensions(100px, 20px);
}

a.zmienHaslo {
	@extend %btn-small;
}

a.dokumenty {
	@extend %btn-small;
}

.sprawdzDostepnosc {
	@extend %btn-small;
	@include btn-dimensions(155px, 20px);
}

#login-box .statusOk .sprawdzDostepnosc,
#login-box .statusError .sprawdzDostepnosc {
	top: 11px;
	left: 300px;
}

.dodajPytanie {
	margin:0 auto;
	display: block;
	@extend %btn-small;
	@include btn-dimensions(105px, 20px);
}

.edytujPytanie {
	margin:0 auto;
	display: block;
	@extend %btn-small;
	@include btn-dimensions(105px, 20px);
}

.edytujOdpowiedz {
	margin:0 auto;
	display: block;
	@extend %btn-small;
	@include btn-dimensions(135px, 27px);
}

#answer-edit-form .right {
	margin-right: 10px;

	.edytujOdpowiedz {
	height: 27px;
	}
}

#cart a.drukuj {
	margin:10px 10px 5px 0;
	float:right;
	font-size: 12px;
	@extend %btn-medium;
	@include btn-dimensions(90px, 31px);
}

.accountBoxLarge.orderHistory a.drukuj {
	line-height: inherit;
}

a.twojeKonto {
	margin:10px 10px 5px 0;
	float:right;
	@extend %btn-medium;
	@include btn-dimensions(118px, 31px);
}

#cart .zmienSposobDostawy {
	@extend %btn-small;
	@include btn-dimensions(160px, 22px);
}

#cart .sprobujPonownie {
	margin:10px 10px 5px;
	float:right;
	@extend %btn-medium;
	@include btn-dimensions(189px, 31px);
}

.wyrazamZgode {
	float: right;
	margin-top: 10px;
	@extend %btn;
	@extend %btn-second;
	@extend %btn-small;
	@include btn-dimensions(122px, 31px);
}

.nieDziekuje {
	float: left;
	margin-top: 10px;
	@extend %btn;
	@extend %btn-fourth;
	@extend %btn-small;
	@include btn-dimensions(105px, 31px);
}

a.btn-sprawdz {
	margin: 0 auto;
	display: block;
	@extend %btn-medium;
	@include btn-dimensions(94px, 31px);
}

a.pokazKluczProduktu {
	@extend %btn-medium;
	display: block;
	margin-bottom: 10px;
	float: right;
}

a.pokazKlucze {
	@extend %btn-medium;
	display: block;
	margin-bottom: 10px;
	float: right;
	margin-left: 80px;
}

.center-content .instrukcjaUzytkownika {
	@extend %btn-small;
	display: block;
	margin-bottom: 10px;
	float: right;
}

a.zamknijPopup {
	@extend %btn-small;
	@include btn-dimensions(70px, 20px);
}

#cancelReservation {
	margin: 16px 0 0 10px;
	@extend %btn-small;
	@include btn-dimensions(140px, 20px);
}

a.skorzystaj {
	float:right;
	@extend %btn-medium;
	@include btn-dimensions(126px, 31px);
}

#cart .zlozWniosek {
	margin: 10px 10px 5px;
	float: right;
	@extend %btn-medium;

	@extend %btn;
	@extend %btn-second;
	position: relative;
}

.cart_summarize a.delete,
.newCart .cart_summarize a.delete {
	@extend %btn-small;
	@include btn-dimensions(19px, 19px);
	border-radius: 50%;
	text-overflow: inherit;

	&:before {
	content: 'x';
	}
}

table.servicesBox td.plus a, table.servicesBox td.minus a {
	@extend %btn-small;
	@include btn-dimensions(19px, 19px);
	border-radius: 50%;
}

table.servicesBox td.plus a:before {
	content: '\002B';
}

table.servicesBox td.minus a:before {
	content: '\002D';
}

.page-navigator-button span.page-navigator-button-current,
span.page-navigator-button-current {
	margin-right:3px; float:left;
	@extend %btn-small;
	@include btn-dimensions(26px, 25px);
	@extend %btn;
	@extend %btn-fourth;
	padding: 0;
}

.page-navigator-button span {
	cursor:pointer; margin-right:3px; float:left;
	@extend %btn-small;
	@include btn-dimensions(26px, 25px);
	@extend %btn;
	@extend %btn-fifth;
	padding: 0;
}

span.btn-prev {
	@extend %btn-small;
}

span.btn-next {
	@extend %btn-small;
}

#question-paginator2,
#paginatorBox2,
#paginatorBox {
	span.btn-next {
	@include btn-dimensions(74px, 25px);
	}

	span.btn-prev {
	@include btn-dimensions(84px, 25px);
	}
}

.ServicesDelivery {
	a {
		line-height: 2.2;
		height: 25px;
		padding: 5px 10px;
	}
}

a.servicesRemoveInvalidForHome {
	@extend %btn-medium;
	@extend %btn;
	@extend %btn-second;
	margin-right: 10px;
}

a.servicesRemoveInvalid {
	@extend %btn-small;
	@extend %btn;
	@extend %btn-fourth;
	margin-left: 10px;
}

a.servicesRemoveInvalidForShop {
	@extend %btn-small;
    @extend %btn;
    @extend %btn-second;
}

a.servicesBackToCart {
	@extend %btn-small;
    @extend %btn;
    @extend %btn-fourth;
}

.order-details:before{
	@extend %icon-file-text2;
	margin: 0 5px 2px 0;
}

.esd-product-availability:before{
	margin: 0 2px 2px 0;
	@extend %icon-cloud;
}

.esd-products-link:before{
	@extend %icon-cloud;
	margin: 0 2px 2px 0;
}

.ekp-products-link:before{
	@extend %icon-cloud;
	margin: 0 2px 2px 0;
}

.add-to-clipboard:before {
	@extend %icon-copy;
	margin: 0 5px 2px 0;
}
