
a.wybieram{background:url(/images/www/button2.png) no-repeat 0 0; width:115px; height:31px;display:block;}
a.wybieram:hover{background-position:0 -31px;}

a.wyloguj {background: url(/images/www/button6.png) no-repeat -477px 0; display: block; width: 63px; height: 20px; }
a.wyloguj:hover{background-position:-477px -20px;}

a.dodaj{background:url(/images/www/button6.png) no-repeat -308px 0;width:48px;height:20px;display:block;}
a.dodaj:hover{background-position:-308px -20px;}

