.shared-cart {
	* {
		box-sizing: border-box;
	}
	font-size: 15px;
  margin-bottom: 30px;
	.page-header {
		background: #f7f7f7;
		margin-bottom: 50px;
		padding: 30px 0;
	}
	.page-title {
		color: #000;
		max-width: $breakpoint-large-width+px;
		margin: 0 auto 10px auto;
	}
	.inventory-link {
		@extend %input;
		height: auto;
		user-select: all;
		-moz-user-select: auto;
		font-size: 15px;
		padding: 10px;
		width: 100%;
	}
	.inventory-products {
		display: table;
		margin: 20px 0;
		width: 100%;
	}
	.total-price {
		display: inline-block;
		vertical-align: middle;
		margin-right: 60px;
		.price {
			color: red;
			font-size: 28px;
			font-weight: bold;
			margin-left: 10px;
		}
		.price-label {
			position: relative;
			top: -5px;
		}
	}
	.order-summary {
		float: right;
		text-align: right;
		width: 50%;
		.add-to-cart {
			@extend %btn-big;
		}
	}
}

.shared-cart-list {
	margin-top: 30px;
	.product-item {
		border-bottom: 1px solid #e4e4e4;
		margin-bottom: 30px;
		padding: 0 0 30px 5px;
		&:after {
			content: '';
			clear: both;
			display: block;
		}
	}
	.product-photo {
		float: left;
		width: 30%;
		img {
			box-shadow: 0 2px 3px rgba(0,0,0,.25);
			height: auto;
			width: 260px;
		}
	}
	.product-info {
		float: left;
		width: 70%;
	}
	.product-price-details > div {
		text-align: right;
	}
	.product-category,
	.product-code,
	.product-price-details {
		color: #949494;
		font-size: 13px;
		margin-bottom: 5px;
	}
	.product-quantity {
		margin-left: 20px;
		font-weight: normal;
	}
	.product-name,
	.product-total-price {
		overflow: hidden;
		font-size: 20px;
		margin: 0 0 5px 0;
		text-align: inherit;
		a {
		display: block;
		float: left;
		max-width: 70%;
		color: #000;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.product-meta, .product-price,
	.warranty-name, .warranty-price,
	.service-name, .service-price {
		float: left;
		margin-bottom: 5px;
	}
	.product-meta,
	.warranty-name,
	.service-name {
		width: 70%;
	}
	.product-price,
	.warranty-price,
	.service-price {
		text-align: right;
		width: 30%;
	}
	.product-info-top {
		padding-bottom: 15px;
	}
	.product-info-bottom {
		border-top: 1px solid #e4e4e4;
		padding: 20px 0;
	}
	.product-info-top:after,
	.product-info-bottom:after {
		content: '';
		clear: both;
		display: block;
	}
}

#modal-restore-shared-cart,
#modal-share-cart {
	width: 350px;
}

#modal-share-cart {
	.label {
		font-size: 14px;
		margin-bottom: 5px;
	}
	.copy {
		margin: 0 10px 0 0;
		@extend %btn-third;
		padding-left: 45px;
		padding-right: 45px;
	}
	.identifier {
		cursor: default;
		font-weight: bold;
		font-size: 16px;
		user-select: all;
	}
	input[type="text"] {
		@extend %input;
		width: 100%;
	}
}

#modal-restore-shared-cart {
	.error-message {
		color: red;
		font-size: 12px;
		display: block;
		font-weight: bold;
		text-transform: uppercase;
	}
	.input-link {
		width: 100%;
	}
}
