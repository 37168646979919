.lowest-price-toggle {
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 8px;
	padding: 5px;
	box-sizing: border-box;
	background: #ffed00;
	width:75px;
	text-transform: uppercase;
	&:after {
		@extend %icon-arrow;
		transform: rotate(90deg);
		margin-left: 3px;
	}
}
.lowest-price-show {
	position: relative;
	.lowest-price-toggle {
		background: #333;
		color: #fff;
		&:after {
			transform: rotate(270deg);
		}
	}
	.lowest-price-dropdown {
		display: block;
	}
}
.lowest-price-dropdown {
	box-sizing: border-box;
	display: none;
	width: 100%;
	background: #eaeaea;
	padding: 10px 15px;
	z-index: 2;
}
.lowest-price {
	.competitor-price {
		text-align: right;
		width: 50%;
	}
	.competitor-name {
		margin-right: 2%;
		width: 48%;
	}
	.buy-lowest-price {
		@extend %btn-second;
		@extend %btn-small;
		width: 100%;
	}
	.competitor-info {
		display: flex;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.competitor-price-list {
		border-bottom: 1px solid #ccc;
		margin-bottom: 10px;
	}
	.competitor-price {
		&:after {
			margin-left: 5px;
		}
		&:not(.is-higher,.is-lower) {
			margin-right: 12px;
		}
		&.is-higher {
			color: #e30613;
			&:after {
				@extend %icon-arrow;
				transform: rotate(270deg);
			}
		}
		&.is-lower {
			color: #3da24c;
			&:after {
				@extend %icon-arrow;
				transform: rotate(90deg);
			}
		}
	}
	.report-difference {
		@extend %btn-link;
		padding-bottom: 0;
		width: 100%;
	}
}

@if($app-katalog == false){
	.lowest-price{
		position: relative;
		margin: 0 -10px -5px;
	}
	.lowest-price-toggle{
		position: absolute;
		top:-33px;
		height: 33px;
		right: 0;
	}
	.product-stocks-box{
		position: relative;
	}
	.lowest-price-dropdown{
		border-bottom:2px solid #333;
	}
}

@if($app-katalog){
	.lowest-price-dropdown {
		position: absolute;
		left: 0;
		top: 45px;
	}
	.lowest-price-show {
		.product-price {
			background: #eaeaea!important;
			position: relative;
			z-index: 2;
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: rgba(0, 0, 0, .65);
			z-index: 1;
		}
	}


	.lowest-price {
		width: 75px;
		.lowest-price-toggle {
			margin-top: 10px;
		}
	}
}
