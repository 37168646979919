@mixin form-flat() {
	background: #fff;
	border: 1px solid $border-color;
}

.flat-input {
  @include form-flat();

  &>input[type=password],
  &>input[type=text], &>input[type=email] {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding-left: 5px;
	margin:0;
	background: transparent;
  }
}

div.input-bg-27, div.input-bg-35 {
	&>input[type=password],
	&>input[type=text] {
		padding-left: 2px;
		padding-right: 2px;
	}
}

input:disabled {pointer-events:none;cursor: default !important;}

div.input-bg-27 {@extend .flat-input; width: 27px; height: 20px;}

div.input-bg-35 {@extend .flat-input; width: 35px; height: 20px;}

div.input-bg-45 {@extend .flat-input; width: 45px; height: 20px;}

div.input-bg-90 {@extend .flat-input; width: 90px; height: 20px;}

div.input-bg-90D {@extend .flat-input;}
div.input-bg-147 {@extend .flat-input; width: 147px; height: 20px;}

div.input-bg-158 {@extend .flat-input; width: 158px; height: 20px;}
div.input-bg-185 {@extend .flat-input;width: 185px; height: 20px;}

div.input-bg-158D {@extend .flat-input;}
div.input-bg-197 {@extend .flat-input; width: 197px; height: 20px;}

div.input-bg-197B {@extend .flat-input; width: 197px; height: 23px;}

input.inputFile{@extend .flat-input; width: 187px; height: 17px;float:left;margin:0 5px 0 0;display:block;padding:2px 5px;}

div.input-bg-570 {@extend .flat-input; width: 570px; height: 20px;}

div.input-bg-540 {@extend .flat-input; width: 540px; height: 20px;}

div.input-bg-337 {@extend .flat-input; width: 337px; height: 20px;}

textarea {outline: none; resize: none;}

.questionForm textarea {height: 15px;line-height: 15px;width:540px;border:1px solid #C9C9C9;padding:5px;font-size: 12px;resize:none;margin-right: 15px;}
.answerForm .question-form textarea {width: 554px;}

textarea.textarea90, div.textarea90 {@include form-flat(); padding:3px 3px 2px; width: 564px; height: 113px; overflow: auto;}
div.textarea90 textarea{width:100%;height:100%;}
textarea.textarea337 {@include form-flat(); font-size:12px;padding:3px; width: 331px; height: 156px; overflow-x: hidden;overflow-y: scroll;}

div.textarea337 {
	@include form-flat();
	box-sizing: border-box;
	font-size:12px;padding:3px;
	width: 337px;
	height: 156px;

	&>.textarea337 {
		border: 0;
	}
}

div.textarea,
div.textarea90,
div.textarea337,
div.textarea349,
div.textarea579 {
	padding: 0;

	&>textarea {
		width: 100% !important;
		height: 100% !important;
		margin: 0 !important;
		padding: 5px;
		box-sizing: border-box;
	}
}

div.textarea {@include form-flat(); box-sizing: border-box; height: 110px; overflow: auto; padding: 3px 3px 2px 3px;width: 529px;}

.comment-form div.textarea {@include form-flat(); width:476px; height:57px;}
#opinionFormAdd div.textarea {width: 537px;}

a.ui-autocomplete-button:hover,
.ui-autocomplete-button {
	box-sizing: border-box;
	cursor:pointer;
	padding:0;
	border: 1px solid $border-color;
	border-left: 0;
	width:24px;
	height:22px;
	line-height: 19px;
	display:inline-block;
	text-align:center;
	&::after{
		@extend %icon-search;
		font-size:14px;
		color:$text-color-lighter;
	};


}

.ui-autocomplete-input {
	padding:0 10px;
	text-overflow: ellipsis;
	overflow:hidden;
	width:180px;
	font-size:11px;
	display:block;
	float:left;
	height:20px;
	line-height:21px;

	background: #fff;
	border: 1px solid $border-color;
	border-right: 0;
}

.sort-select {
	width: 150px;
	float: left;
}

.quantity-select {
	width: 50px;
	float: left;
}

.questions-select {
	width: 150px;
	float: left;
}

label.checkbox-css+label {
	margin-left: 5px;
	line-height: 18px;
}

.flat-select {
	position: relative;
	z-index: 2;
}

input[type='checkbox']:disabled + i,
input[type='radio']:disabled + i:before {
	background: #efefef;
}

.iframe-error-form {
	overflow: hidden;

	div.errorBox.statusError, div.errorBox.statusOk {
		margin: 4px 0 4px -1px;
	}
}

#euroagreement_label {
	vertical-align: top;

	&+div.textDiv {
		display: inline-block;
		width: 450px;
	}
}

.formal-agree {
	width: 475px;
	margin-left: 5px;
}
