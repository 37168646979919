$app: "euro";
$rwd: false;
$front: "desktop";
/* Style EURO - sklep
author: pgrzech
*/

body, td, textarea {font-family: arial, sans-serif;font-size: 11px;color:#333;}

body {text-align: center;}

textarea, input {color:#666666;	margin:0px; padding:0px; border: 0; font-size:11px;}
a  {color:#0c4da2; text-decoration:none;outline: none;}
.hover  {background-position:100% 100% !important;}

a:hover  {text-decoration:underline;}
a span.hoverButton{cursor:pointer;}
a:hover span.hoverButton, a.hoverButton:hover{background-position:100% 100% !important;}
a.nodecoration:hover{text-decoration:none !important;}
a.nodecoration:hover span.decoration{text-decoration:underline;	}

html, body, div, p, .no_margins, .no_margins li, img, form { margin:0px; padding:0px; }
img { border:0px;}
img.lazy {width:100%;}

.hide {display:none !important;}
.none {display:none}
.block {display:block !important;}
.inline {display: inline !important;}

.fright {float:right !important;}
.fleft {float:left !important;}
.fnone {float:none !important;}
.valign {vertical-align:middle;}
.valignB {vertical-align:bottom !important;}
.bold {font-weight: bold !important;}
.normal{font-weight: normal !important;}
.lh13{line-height:13px;}
.strike{text-decoration:line-through;}
.eventsNone{pointer-events:none;}
/*marginesy */
.m0a {margin: 0 auto !important;}
.m0 {margin: 0px !important;}
.m3 {margin: 3px !important;}
.m5 {margin: 5px !important;}
.m10 {margin: 10px !important;}
.mT0 {margin-top: 0px !important;}
.mT1 {margin-top: 1px !important;}
.mT2 {margin-top: 2px !important;}
.mT3 {margin-top: 3px !important;}
.mT5 {margin-top: 5px !important;}
.mT6 {margin-top: 6px !important;}
.mT7 {margin-top: 7px !important;}
.mT8 {margin-top: 8px !important;}
.mT10 {margin-top: 10px !important;}
.mT15 {margin-top: 15px !important;}
.mT20 {margin-top: 20px !important;}
.mT50 {margin-top: 50px !important;}
.mT100 {margin-top: 100px !important;}
.mR0 {margin-right: 0px !important;}
.mR2 {margin-right: 2px !important;}
.mR3 {margin-right: 3px !important;}
.mR5 {margin-right: 5px !important;}
.mR10 {margin-right: 10px !important;}
.mR15 {margin-right: 15px !important}
.mR20 {margin-right: 20px !important;}
.mR40 {margin-right: 40px !important;}
.mB0 {margin-bottom: 0px !important;}
.mB3 {margin-bottom: 3px !important;}
.mB5 {margin-bottom: 5px !important;}
.mB10 {margin-bottom: 10px !important;}
.mB15 {margin-bottom: 15px !important;}
.mB20 {margin-bottom: 20px !important;}
.mL0 {margin-left: 0px !important;}
.mL2 {margin-left: 2px !important;}
.mL3 {margin-left: 3px !important;}
.mL5 {margin-left: 5px !important;}
.mL10 {margin-left: 10px !important;}
.mL15 {margin-left: 15px !important;}
.mL20 {margin-left: 20px !important;}
.mL25 {margin-left: 25px !important;}
.mL30 {margin-left: 30px !important;}
.mL50 {margin-left: 50px !important;}
.mL95 {margin-left: 95px !important;}
.mL235 {margin-left: 235px !important;}


/* paddingi */
.p0 {padding: 0px !important;}
.p3 {padding: 3px !important;}
.p5 {padding: 5px !important;}
.p10 {padding: 10px !important;}
.p12 {padding: 12px !important;}
.p15 {padding: 15px !important;}
.p20 {padding: 20px !important;}
.pT0 {padding-top: 0px !important;}
.pT2 {padding-top: 2px !important;}
.pT3 {padding-top: 3px !important;}
.pT5 {padding-top: 5px !important;}
.pT10 {padding-top: 10px !important;}
.pT15 {padding-top: 15px !important;}
.pT20 {padding-top: 20px !important;}
.pT30 {padding-top: 30px !important;}
.pR0 {padding-right: 0px !important;}
.pR3 {padding-right: 3px !important;}
.pR5 {padding-right: 5px !important;}
.pR10 {padding-right: 10px !important;}
.pR15 {padding-right: 15px !important;}
.pR20 {padding-right: 20px !important;}

.pR40 {padding-right: 40px !important;}
.pB0 {padding-bottom: 0px !important;}
.pB3 {padding-bottom: 3px !important;}
.pB5 {padding-bottom: 5px !important;}
.pB10 {padding-bottom: 10px !important;}
.pB15 {padding-bottom: 15px !important;}
.pB20 {padding-bottom: 20px !important;}
.pTB30{padding-top:30px;padding-bottom:30px;}
.pL0 {padding-left: 0px !important;}
.pL3 {padding-left: 3px !important;}
.pL5 {padding-left: 5px !important;}
.pL10 {padding-left: 10px !important;}
.pL15 {padding-left: 15px !important;}
.pL20 {padding-left: 20px !important;}
.pL30 {padding-left: 30px !important;}
.pL25 {padding-left: 25px !important;}
.pL40 {padding-left: 40px !important;}
.pL235 {padding-left: 235px !important;}

/* bordery */
.bA {border: 1px solid #CACACA !important;}
.bAcred{border: 1px solid #ed1c24 !important;}
.b0	{border: none !important;}
.bT {border-top: 1px solid #CACACA !important;}
.bT2 {border-top: 2px solid #CACACA !important;}
.bT0 {border-top: none !important;}
.bR {border-right: 1px solid #CACACA !important;}
.bR0 {border-right: none !important;}
.bB {border-bottom: 1px solid #CACACA !important;}
.bB0 {border-bottom: none !important;}
.bL {border-left: 1px solid #CACACA !important;}
.bL0 {border-left: none !important;}
.bTRL {border-top: 1px solid #CACACA !important; border-right: 1px solid #CACACA !important; border-left: 1px solid #CACACA !important;}
.bRBL {border-right: 1px solid #CACACA !important; border-bottom: 1px solid #CACACA !important; border-left: 1px solid #CACACA !important;}
.bRL {border-right: 1px solid #CACACA !important; border-left: 1px solid #CACACA !important;}

/* fonty */
.f0 {font-size: 0px;}
.f9 {font-size: 9px;}
.f10 {font-size: 10px !important;}
.f11 {font-size: 11px !important;}
.f12 {font-size: 12px !important;}
.f13 {font-size: 13px !important;}
.f13i {font-size: 13px !important;}
.f14 {font-size: 14px !important;}
.f15 {font-size: 15px;}
.f16 {font-size: 16px;}
.f18 {font-size: 18px !important;}
.f20 {font-size: 20px;}
.f24 {font-size: 24px;}
.f25 {font-size: 25px;}
.f26 {font-size: 26px;}
.f28 {font-size: 28px;}
.f30 {font-size: 30px;}

/* długości */
.w10 {width: 10%;}
.w20 {width: 20%;}
.w20p{width: 20px !important;}
.w25 {width: 24%;}
.w30 {width: 30%;}
.w30p {width: 30px;}
.w35p {width: 35px;}
.w40 {width: 40%;}
.w50 {width: 50%;}
.w50p {width: 50px !important;}
.w60 {width: 60%;}
.w60p {width: 60px;}
.w70 {width: 70%;}
.w72 {width: 72%;}
.w75 {width: 75%;}
.w80 {width: 80%;}
.w80p {width: 80px;}
.w90 {width: 90%;}
.w90p {width: 90px;}
.w100 {width: 100%;}
.w100p {width: 100px;}
.w105p{width:105px}
.w110 {width: 110px;}
.w110i {width: 110px !important;}
.w115p{width:115px}
.w120{width:120px}
.w130{width:130px !important;}
.w140{width:140px}
.w150{width:150px !important;}
.w160{width:160px}
.w170{width:170px}
.w175{width:175px}
.w185{width:185px;}
.w190{width:190px}
.w195{width:195px}
.w200{width:200px}
.w213{width:213px}
.w248{width:248px}
.w250	{width:250px}
.w260	{width:260px !important;}
.w280	{width:280px}
.w300	{width:300px}
.w310	{width:310px}
.w330	{width:330px !important;}
.w345	{width:345px}
.w350	{width:350px}
.w360p	{width:360px}
.w370	{width:370px}
.w375	{width:375px}
.w380	{width:396px}
.w400	{width:400px}
.w430	{width:430px}
.w450	{width:450px}
.w460	{width:460px}
.w465	{width:465px}
.w480	{width:480px}
.w500	{width:500px}
.w520	{width:520px}
.w590	{width:580px}
.w658p 	{width:658px}
.w678p 	{width:678px}
.w680p 	{width:680px}
.w740p 	{width:740px}

/* separatory */
hr.cart-services-bottom-line {
	height: 1px;
	color: #c9c9c9;
	background: #c9c9c9;
	font-size: 0;
	border: 0;
	width: 66%;
    margin: 15px auto 5px;
}

/* wysokości */
.h0{height:0px !important;font-size:0 !important;}
.h5{height:5px !important;font-size:0 !important;}
.h6{height:6px !important;font-size:0 !important;}
.h7{height:7px !important;font-size:0 !important;}
.h10{height:10px}
.h15{height:15px}
.h20{height:20px}
.h23{height:23px}
.h50{height:50px}
.hAuto{height:auto !important;}

/* kolory czcionek */
.c333{color: #333333 !important;}
.c000{color: #000000 !important;}
.c999{color: #999999 !important;}
.c666{color: #666666 !important;}
.cyellow{color: #c4a805 !important;}
.cred {color:#ed1c24 !important;}
.cred2 {color:#ff3333 !important;}
.cblue {color:#0c4da2 !important;}
.cwhite {color: #FFF !important;}
.clightgrey{color: #B4B4B4 !important;}
/* spany */
span.cblue {color:#0c4da2;}
span.cred {color:#ed1c24;}
.row label em{color:#ed1c24;}
/* backgroundy */
.nobg {background: none !important;}
.bglightblue{ background: #f2f2f2 !important;}
.bgwhite{ background: #fff!important;}

/* teksty */
.tcenter {text-align: center !important;}
.tright {text-align:right !important;}
.tjustify {text-align:justify !important;}
.dec-none {text-decoration: none !important;}
.tleft, .forms .tleft {text-align:left !important;}
.half {width:50%;}
.txtUnder {text-decoration:underline;}
.nowrap {white-space: nowrap;}
.upper {text-transform: uppercase;}
.over-hidden {overflow: hidden;}
.break-word{word-wrap: break-word;}
.hidden{visibility: hidden;}

.buttonA{text-align:center}
.buttonA a{display:block;margin:0 auto}
.buttonA div a{margin:0 4px;float:left;}
.buttonA div {margin:0 auto;}

/* inputy */

.errorBox .HouseNumber label.error,
.errorBox .loginRow label.error{position:static;}

/* reszta */

.clear, clear2 {clear:both;}
#hiddenFancyLink, #hiddenFancyBody{display:none;}
.pointer {cursor:pointer;}
.relative {position: relative;}

h1, h2 {font-size:13px;}
h3 {padding:0px 5px;font-size:12px;}
h4 {font-size:10px;}

li .clear {height:5px; float:none;}
span.clear {display:block; height:1px; overflow:hidden;}
div .clear {height:1px; overflow:hidden; background: transparent;}

h4 a,  .titleH4 a, h3 a{color:#000000;font-size:13px;text-decoration:none;}
h4.title, div.titleH4 {color:#333333;font-size:13px; font-weight: bold;}
.li-disc {list-style:disc;}

/* ogolne spany obrazki */
span.arrow-grey{background:url(/images/www/arrow-grey.gif); display:block;float:left;height:5px;margin:4px 4px 0 0;width:3px; font-size: 0;}
span.arrow-blue-up{background:url(/images/www/arrow-blue-up.gif) 50% 50% no-repeat; margin-left: 2px;}
span.arrow-blue-down{background:url(/images/www/arrow-blue-down.gif) 50% 50% no-repeat; margin-left: 2px;}
span.arrow-black{background:url(/images/www/arrow-black.gif); display:block;float:left;height:5px;margin:4px 4px 0 0;width:3px; font-size: 0;}
span.report-error{background:url(/images/www/report-error.gif); display:block;float:left;height:12px;margin:0 4px 0 0;width:14px; font-size: 0;}

span.ball {background: url(/images/www/product-info-dot.gif) 0 0 no-repeat;float:left;margin:4px 4px 0 0;display:block;width:7px;height:7px;}
span.ball-yes {background: url(/images/www/product-desc-tick-yes.gif) 0 0 no-repeat;position:relative;left:-1px;float:left;margin:4px 0 0 0;display:block;width:12px;height:10px;}
span.ball-no {background: url(/images/www/product-desc-tick-no.png) 0 0 no-repeat;position:relative;left:-1px;padding-right:5px;float:left;margin:4px 5px 0 0;display:block;width:12px;height:10px;}

span.clipboard { cursor:pointer;background:url(/images/www/btn-clipboard.gif) no-repeat scroll left 0 transparent;display:block;font-size:12px;font-weight:bold;padding:3px 0 0 21px;width:130px;}
span#recommend, span.recommend { background:url(/images/www/recommend-btn.gif) 0% 30% no-repeat; display:block; height:16px; margin-top:7px; padding-left:20px; width:110px;}
span#print, span.print { background:url(/images/www/print-btn.gif) 0 0 no-repeat; display:block; height:16px; margin-top:7px; padding-left:20px; width:110px;}
a:hover span#recommend, a:hover span#print {text-decoration:underline;}

.blue span.rollin {background: url(/images/www/btn-roll-b.png) -51px 0 no-repeat;}
.blue span.rollout {background: url(/images/www/btn-roll-b.png) 0 0 no-repeat;}
.red span.rollin {background: url(/images/www/btn-roll-r.png) -51px 0 no-repeat;}
.red span.rollout {background: url(/images/www/btn-roll-r.png) 0 0 no-repeat;}

.rel-5 {position: relative; top: -5px;}
.rel-8 {position: relative; top: -8px;}
.rel10 {position: relative; left: 10px;}
.rel{position: relative; }

/* --------------------------------------------------------------------
	Podstawowy kontener strony
-------------------------------------------------------------------- */

#container { margin:auto; padding:5px 0 0; width:960px; position:relative}


#content-1c {width:940px}
#content-2c {width:680px; float:left;}
#content-3c {width:532px; float: left;overflow:hidden;}



/* --------------------------------------------------------------------
	1-klumnowy układ
-------------------------------------------------------------------- */


#content-1c .center-content {background: white; border: 1px solid #CACACA; border-top: 0 none; border-bottom: 0 none;}

#container.recovery-password .content{width: 450px; margin: 0 auto;}
#container.recovery-password #content-1c {width: auto;}
#container.recovery-password #content-1c .center-top-bar.grey{padding:8px 0 0 10px; height:24px; background: #cbcbcb;}
#container.recovery-password #content-1c .center-content{border: 1px solid #CACACA;}

.recovery-password .error-box:before {margin: 0 0 12px 5px;}
.recovery-password .password-invalid {padding-left: 135px;}
.recovery-password .password-invalid .password-toggle {right: 50px;}

/* --------------------------------------------------------------------
	2-klumnowy układ
-------------------------------------------------------------------- */



/* --------------------------------------------------------------------
	Lewa kolumna 2-klumnowy układ
-------------------------------------------------------------------- */
#definitions #left-column {margin-left: 10px;}
#left-column { float:left; width:250px; text-align:center; margin-right:9px;overflow:hidden;}
.loader{width:248px}
#left-column div, #left-column p  { text-align:left; font-size:11px;}
#left-column div.clear{font-size:0px;}
#left-column .h5{margin-bottom:5px;}
#left-column .left-box-bar,#left-column .left-box-bar2 {font-size:0px; margin:0px; text-align: center; color:black;}
#left-column .left-box-bar h2, #left-column .left-box-bar .h2 {color:#333333;padding:9px 0 0 0;font-weight:bold;font-size:13px;text-align:center;}
#left-column .left-box-bar.header-big-blue .h2{color: #fff;}
#left-column .left-box-bar2 h2{color:#333333;font-size:14px;padding:10px 0 0 0;height:22px;}

#left-column .yellow .left-box-top {font-size:0; background: transparent url(/images/www/left-box-yellow.png) 0 -41px no-repeat; height: 5px;}
#left-column .yellow .left-box-top-content {padding:10px; background: #fff; border-left: 2px solid #ffe95b;  border-right: 2px solid #ffe95b;}
#left-column .yellow .left-box-center a.show {display:block;float:right;background:url(/images/www/button3.png) -135px 0 no-repeat; width:47px; height:20px;}
#left-column .yellow .left-box-center a.show:hover {background-position:-135px -20px}
#left-column .yellow .left-box-center a.showBig {display:block;background:url(/images/www/button4.png) -602px 0 no-repeat; margin:10px auto;width:222px; height:31px;}
#left-column .yellow .left-box-center a.showBig:hover {background-position:-602px -31px}
#left-column .yellow .left-box-center a {width:225px; display: block; color: #333333;}
#left-column .yellow .left-box-center a.fancyDict{text-decoration:none;display:inline;background:url(/images/www/add_info.png) no-repeat right center;}
#left-column .yellow .left-box-center a.label {margin-left: 5px; overflow:hidden; width:198px;}
#left-column .yellow .left-box-center a.isLast {width:148px;}
#left-column .yellow .left-box-center ul.child li a.active span.fleft {font-weight:bold;}
#left-column .yellow .left-box-center label.find-sim-lab {margin:1px 0 0 5px;width: 195px;}
#left-column .yellow .left-box-center a.status {width: 175px;}
#left-column .yellow .left-box-center a.status1{color:#17BF2E;}
#left-column .yellow .left-box-center a.status2{color:#CF1919;}
#left-column .yellow .left-box-center a.status3{color:#09409F;}
#left-column .left-box-center li {margin: 0px 0 0 0;padding:0;}
#left-column .yellow .left-box-center li.first {margin: 0;}
#left-column .yellow .left-box-center ul.border-list li { margin:0; border-bottom: 1px solid #f1edcc;}
#left-column .yellow .left-box-center ul li.last {border: 0 none;}
#left-column .yellow .left-box-center ul li label a:hover {text-decoration: underline;}
#left-column .yellow .left-box-center ul li a {padding:5px 10px 5px 10px;text-decoration: none;}
#left-column .yellow .left-box-center ul li a:hover {background-color:#FFFDEF;}

#left-column .yellow .left-box-center ul li a:hover span.fleft {text-decoration: underline; cursor: pointer;}
#left-column .yellow .left-box-center ul.border-list li a span.fleft {font-size: 12px; color: #333333; font-weight: bold; display: block;width:188px;}
#left-column .yellow .left-box-center ul.border-list li a.more span.fleft{width:auto;padding: 8px 5px 5px 0;}
#left-column .yellow .left-box-center ul.border-list li a.more span.arrow-black{margin-top:12px;}
.unity ul.border-list li a span.fleft{width:227px !important;}
#left-column .yellow .left-box-center ul.border-list li a span.fright {font-size: 12px; color: #c4a805; font-weight: bold;}
#left-column .yellow .left-box-center .has-children {background:#fffdef;}
#left-column .yellow .left-box-center li a.arrow-up{background-image:url(/images/www/arrow-black-up.png);background-repeat:no-repeat;background-position:227px 10px;}
#left-column .yellow .left-box-center li a.arrow-down{background-image:url(/images/www/arrow-black-down.png);background-repeat:no-repeat;background-position:227px 10px;}
#left-column .yellow .left-box-center ul.child li {border: 0 none; margin-right: 5px;}
#left-column .yellow .left-box-center ul.child li a {width:225px;}
#left-column .yellow .left-box-center ul.child li a span.fleft{font-size: 11px; color: #333333; font-weight: normal;}
#left-column .yellow .left-box-center ul.child li a span.fright{font-size: 11px; color: #c4a805; font-weight: normal;}

#left-column .yellow .left-box-center ul.childchild{background:#ffffff;}
#left-column .yellow .left-box-center ul.childchild li{background:#ffffff;}
#left-column .yellow .left-box-center ul.childchild li a {padding-left:20px;width:215px;}

#left-column .grey .left-box-bar-blue {text-align:center;margin:0;padding:6px 0 0;position:relative;  height:24px; background:url(/images/www/left-box-blue.png) 0 -43px no-repeat transparent;}

#left-column .grey .left-box-bar a.roll-btn {position: absolute; top: 5px; right: 10px; z-index: 2; cursor: pointer;}

#left-column .grey .left-box-bar a.roll-btn:hover span.rollout{background-position:0 -20px !important;}
#left-column .grey .left-box-bar a.roll-btn:hover span.rollin{background-position:-51px -20px !important;}

#left-column .grey .left-box-center p {margin: 0;}
#left-column .grey .left-box-center p.category {display:block;width:100px;}
#left-column .grey .left-box-center p.name {margin: 0 0 15px 0; font-size: 12px;}
#left-column .grey .left-box-center p.price { font-size:18px;}
#left-column .grey .arrows {height:31px; padding: 0;}
#left-column .left-box-center span.arrow {display: block; width: 22px; height: 15px; float: left;}
#left-column .left-box-center span.arrow.down {background: transparent url(/images/www/arrow-down.gif) left bottom no-repeat;}
#left-column .left-box-center span.arrow.up {background: transparent url(/images/www/arrow-up.gif) left bottom no-repeat;}
#left-column .left-box-bottom-lightblue {font-size:0; background: transparent url(/images/www/left-box-grey.png) 0 -41px no-repeat; height: 6px;}

#left-column .left-box-top{font-size:0; background: transparent url(/images/www/left-box-top.gif) left bottom no-repeat; height: 5px;}

#left-column .blue1 .left-box-top {font-size:0; height: 5px;}
#left-column .blue1 .left-box-top-content {padding:10px; background: #fff; border-left: 2px solid #0f4387;  border-right: 2px solid #0f4387;}
#left-column .blue1 .left-box-bar { height:35px; background:url(/images/www/left-box-blue.png) 0 0 no-repeat transparent;}
#left-column .blue1 .left-box-bar.header-big-blue{height: 50px; background-color:#104790;}
#left-column .blue1 .left-box-center {background:#e7edf5 url(/images/www/left-box-con-blue1-bg.gif) 0 0 repeat-x; border-left: 2px solid #0f4387;  border-right: 2px solid #0f4387;}
#left-column .blue1 .left-box-bottom {font-size:0; background: transparent url(/images/www/left-box-blue.png) 0 -35px no-repeat; height: 8px;}
#left-column .blue1 .left-box-center ul.border-list li { border-bottom: 1px solid #d5dae1;}
#left-column .blue1 .left-box-center ul.border-list li a span.fleft {font-size: 12px; color: #333333; font-weight: bold; display: block; width:180px;}
#left-column .blue1 .left-box-center ul.border-list li a span.fright {font-size: 12px; color: #6b8ebb; font-weight: bold;}
#left-column .blue1 .left-box-center .has-children {background:#f5f8fb;}
#left-column .blue1 .left-box-center ul.child li {border: 0 none; margin-right: 5px;}
#left-column .blue1 .left-box-center ul.child li a {width:210px;}
#left-column .blue1 .left-box-center ul.child li a span.fleft{font-size: 11px; color: #333333; font-weight: normal;}
#left-column .blue1 .left-box-center ul.child li a span.fright{font-size: 11px; color: #6b8ebb; font-weight: normal;}
#left-column .blue1 .left-box-center a {width:220px; display: block;color:#333333;}
#left-column .blue1 .left-box-center ul li a:hover{background:#EFF6FF}
#left-column .blue1 .left-box-center label {margin-left: 5px;}
#left-column .blue1 .left-box-center label a.fleft {width: 150px; color: #333333;}
#left-column .blue1 .left-box-center li {margin: 5px 0 0 0;}
#left-column .blue1 .left-box-center li.first {margin: 0;}
#left-column .blue1 .left-box-center ul li.last {border: 0 none;}

/* --------------------------------------------------------------------
	Prawa kolumna 2-klumnowy układ
-------------------------------------------------------------------- */
.right-column-2c { overflow:hidden;margin:0 0 0 10px !important;}

/* --------------------------------------------------------------------
	Układ 3-kolumnowy
-------------------------------------------------------------------- */

#right-3-column{width:138px;float:right;overflow:hidden;}
#right-3-column .right-box-bar {padding:7px 0 0;height:24px;background:url(/images/www/bo_138.png) no-repeat 0 0;}
#right-3-column .right-box-bottom{background:transparent url(/images/www/bo_138.png) no-repeat 0 -31px;font-size:0;height:9px;}
#right-3-column .right-box-center {border-left:1px solid #cecece;border-right:1px solid #cecece;background:#ffffff;}

#content-3c	.top{background:transparent url(/images/www/bo_532.png) no-repeat 0 -12px;font-size:0;height:5px;}
#content-3c	.center{border-left:1px solid #cecece;border-right:1px solid #cecece;background:#ffffff;padding:4px 10px 5px;}
#content-3c	.top2{background:transparent url(/images/www/bo_532.png) no-repeat 0 0;font-size:0;height:4px;}
#content-3c	.center2{border-left:1px solid #cecece;border-right:1px solid #cecece;background:#f5f5f5;padding:5px 5px 7px 10px;border-bottom:1px solid #cecece;}
#content-3c	.center3{border-left:1px solid #cecece;border-right:1px solid #cecece;background:#ffffff;padding:4px 0 5px;}
#content-3c	.bottom{background:transparent url(/images/www/bo_532.png) no-repeat 0 -4px;font-size:0;height:8px;}
#content-3c	.bottom2{background:transparent url(/images/www/bo_532.png) no-repeat 0 -17px;font-size:0;height:8px;}
#content-3c	.bottomHeader,
#content-3c .bottomPaging{border-left:1px solid #cecece;border-right:1px solid #cecece;background:#ffffff;border-top:1px solid #cecece;padding:5px 10px 6px;}
#content-3c .bottomPaging{border-top:0;display:none;}
.page-navigator a{float:left;}
.page-navigator a:hover{text-decoration:none;}

.lazy {width:100%;}
.productPhoto, #see-also .productPhoto{margin:0 auto;width:71px;height:63px;display:block;overflow:hidden;position:relative;}
.productPhotoS{width:102px;height:91px;position:relative;}
.productPhotoSS{width:71px;height:63px;position:relative;}
.productPhotoSuite{display:block;width:79px;height:69px;position:relative;margin:0 auto;}
.productPhotoSuite:hover span{text-decoration:none;}
.productPhotoSuite img{margin:3px 4px !important;}
span.label-rates-S {text-indent:-1000px;overflow:hidden;background-position:right bottom;background-repeat:no-repeat;bottom:0;display:block;height:91px;position:absolute;right:0;width:102px;}
span.label-rates-SS {text-indent:-1000px;overflow:hidden;background-position:right bottom;background-repeat:no-repeat;bottom:0;display:block;height:63px;position:absolute;right:0;width:71px;}
span.label-shipping-S {text-indent:-1000px;overflow:hidden;background:url(/images/www/dostawa_gratis_s.gif) no-repeat top left;top:0;display:block;height:91px;position:absolute;right:0;width:102px;}
span.label-shipping-SS {text-indent:-1000px;overflow:hidden;background:url(/images/www/dostawa_gratis_s.gif) no-repeat top left;top:0;display:block;height:63px;position:absolute;right:0;width:71px;}
span.label-promotion-S {text-indent:-1000px;overflow:hidden;background:transparent no-repeat;top:0;display:block;height:91px;position:absolute;right:0;width:102px;}
span.label-promotion-SS {text-indent:-1000px;overflow:hidden;background:transparent no-repeat;top:0;display:block;height:63px;position:absolute;right:0;width:71px;}
span.label-suites-S {text-indent:-1000px;overflow:hidden;background:transparent no-repeat;top:0;display:block;position:absolute;right:0;display:block;width:79px;height:69px;}
span.label-suites-S2 {overflow:hidden;top:21px;display:block;position:absolute;left:0;display:block;width:21px;font-size:22px;color:#0C4DA2;height:23px;text-align:center;line-height:23px;}
/* --------------------------------------------------------------------
	Tresc wlasciwa strony dla ukladu 2-kolumnowego
-------------------------------------------------------------------- */

#content-2c .center-top-bar {position:relative; padding: 10px 0 0 15px; font-size:16px;}
.center-top-bar h1, .center-top-bar .h1 .center-top-bar h2, .center-top-bar h4, #bottomPomoc h2 {overflow: hidden;height:18px;}
.center-top-bar h1 { font-size: 13px; margin: 2px 0; }
#content-2c .center-top-bar span.new {position:absolute; top:2px; right:2px; display:block; width:65px; height:33px; background: url(/images/www/bar-black-btn-new.gif) 0 0 no-repeat;}
#content-2c .grey span.new {position:absolute; top:0px; right:1px; display:block; width:65px; height:31px; background: url(/images/www/bar-grey-btn-new.gif) 0 0 no-repeat;}
#content-2c .center-top-bar span.promotion{position:absolute; top:2px; right:2px; display:block; width:73px; height:33px; background: url(/images/www/bar-black-btn-promotion.gif) 0 0 no-repeat;}
#content-2c .grey span.promotion{position:absolute; top:0px; right:1px; display:block; width:73px; height:31px; background: url(/images/www/bar-grey-btn-promotion.gif) 0 0 no-repeat;}
#content-2c .center-top-bar span.promotion-internet{position:absolute; top:2px; right:2px; display:block; width:91px; height:33px; background: url(/images/www/bar-black-btn-promotion-internet.gif) 0 0 no-repeat;}
#content-2c .grey span.promotion-internet{position:absolute; top:0px; right:1px; display:block; width:91px; height:31px; background: url(/images/www/bar-grey-btn-promotion-internet.gif) 0 0 no-repeat;}
#content-2c .center-top-bar span.sale {position:absolute; top:2px; right:2px; display:block; width:83px; height:33px; background: url(/images/www/bar-black-btn-sale.gif) 0 0 no-repeat;}
#content-2c .grey span.sale {position:absolute; top:0; right:1px; display:block; width:83px; height:31px; background: url(/images/www/bar-grey-btn-sale.gif) 0 0 no-repeat;}

#content-2c .center-top-bar.blackp0 {padding: 0; height:36px;}
#content-2c .center-top-bar.blackp0 h2 {width:575px;}

#content-2c .center-top-bar.black h1 {color: white;}

#content-2c .center-top-bar.blue{height:24px; padding: 6px 0 0 0; background: url(/images/www/center-bar.png) 0 -68px no-repeat; text-align:center;}
#content-2c .center-top-bar.blue h1,#content-2c .center-top-bar.blue .h1 {color: white;text-align:center;font-weight:bold;font-size:13px;}

#content-2c .center-content, .content-2c .center-content {background: white; border: 1px solid #CACACA; border-top: 0 none; border-bottom: 0 none;}

content-2c .center-content.bg-blue {background:#e7edf5 url(/images/www/conent-blue-bg.gif) 0 0 repeat-x; }

.center-content .grey-bg-center {background: url(/images/www/grey-dot.gif) 50% 0 repeat-y;}
.center-content .grey-bg-center2 {background: url(/images/www/grey-2dot.png) 50% 0 repeat-y;}

#content-2c .bglightblue.btns {padding:8px 10px 5px 10px; height:21px; }
#content-2c .bglightblue span.btn {display: block; height: 21px;}

#content-2c .center-bottom-lightblue {height: 5px; background: url(/images/www/center-bar.png) left -98px no-repeat;}

.under-wrapper { clear:both; padding:8px 0px 6px; font-size: 12px;}
.print-bar { text-align:right;}
.print-bar a {margin-right:10px;}

/* -----------------------
	Style Produktu
	---------------------- */

.price {font-weight:bold; color: #333333; font-size:18px;white-space:nowrap;; }
.oldprice {font-weight:bold; color: #333333; font-size:13px;white-space:nowrap; text-decoration: line-through; }
.category {color:#999999;font-size: 11px;}
.addedToCart{ display: none; font-weight: bold; margin: 8px 0 0 5px;float: left;}

a:hover span.addToCart-big{background-position:0 -31px;}
div.category {height: 28px; overflow: hidden;}
#right-3-column div.category{height:auto;}

ul#see-also li div{text-align: center;}
ul#see-also li .see-photo {text-align:center;}
ul#see-also li .basics h4 {height: 35px; overflow: hidden; margin-bottom: 3px;}
#see-also{width:680px; height: 160px;overflow:hidden;}

.see-also li {width: 136px !important;height: 160px !important;}
.see-also li .bR{height: 160px;padding:0 5px;}
.seeAlsoBlock{margin-bottom:5px;display:none;}
.borderLeft{z-index:2;position:absolute;top:0;left:0;width:1px;border-left:1px solid #CACACA;height:160px;}
.borderRight{z-index:2;position:absolute;top:0;right:0;width:1px;border-right:1px solid #CACACA;height:160px;}
.seeAlsoBlock .borderLeft{height:160px;}
.seeAlsoBlock .borderRight{height:160px;}

/* -----------------------------------
	karta produktu product-card-common
	---------------------------------- */

span.label-promotion {text-indent:-1000px;overflow:hidden;position:absolute;right:0px;bottom:0px;background:transparent no-repeat scroll;display:block;height:100%;width:100%;}
span.label-shipping {text-indent:-1000px;overflow:hidden;position:absolute;right:0px;bottom:0px;background:transparent url(/images/www/dostawa_gratis_m.gif) no-repeat scroll 0 0;display:block;height:100%;width:100%;}
span.label-rates {text-indent:-1000px;overflow:hidden;position:absolute;right:0px;bottom:0px;background-repeat: no-repeat;background-position:right bottom;display:block;height:100%;width:100%;}

.productImage{position:relative;height:169px;width:190px;display:block;}
.productImage img.lazy{display: block;height: 169px;width: 190px;}
.productImageSmall{position:relative;height:91px;width:102px;display:block;margin:0 auto;margin-top:5px;}
.productImageSmall .lazy{width:100%;}
.productImageSmall span.label-rates{height:91px;width:102px;}
a.promotionLogo{position:absolute;}


.pC1{display:block;float:left;width:9px;height:52px;}
.pC2{display:block;float:left;width:9px;height:52px;}

a.pC1{cursor:auto;background:url(/images/www/bg-str.png) no-repeat -18px -20px;}
a.pC2{cursor:auto;background:url(/images/www/bg-str.png) no-repeat -27px -20px;}
a.pC1a{cursor:pointer;background-position: 0px center;}
a.pC2a{cursor:pointer;background-position: -9px center;}

.description {float: left; width: 280px;margin-left:5px; }
.description .info {padding: 4px 0; border-top: 1px solid #DFDFDF;}
.description .info h2, .description .info .h4{font-size: 11px;display:block;}
.description .info span.bargain {margin:0 3px 0 0;  display: block; float: left; width: 14px; height: 18px;}
.description .prod-error{position: absolute; top:0;left:0; width:390px;}
.description .prod-star-title{float:left;margin:0 5px 0 0;}

.dynamicDescription{width:658px;padding:10px;clear:both;display:table;}
.dynamicDescription.dynamicDescription-HALF > div {display:table-cell;vertical-align: middle;}
.dynamicDescription.dynamicDescription-FULL > div {display:table-row;vertical-align: middle;}
.dynamicDescription > div {display:table-cell;vertical-align: middle;}
.dynamicDescription .desc{text-align:justify;}
.dynamicDescription.dynamicDescription-HALF .col-desc{width:318px;}
.dynamicDescription-FULL .col-desc, .dynamicDescription-noImg .col-desc{padding-left:0px;padding-right:0px;}
.dynamicDescription.dynamicDescription-FULL .col-desc .desc{padding-bottom:10px;}
.dynamicDescription .col-img{vertical-align:middle;text-align:center;}
.dynamicDescription.dynamicDescription-HALF .col-img{width:320px;height:208px;}
.dynamicDescription.dynamicDescription-FULL .col-img{width:658px;height:260px;}
.dynamicDescription.dynamicDescription-FULL .col-img > div{display:table-cell;vertical-align:middle;text-align:center;}
.dynamicDescription .name{font-weight:bold;font-size:13px;padding-bottom:12px;}
.dynamicDescription.dynamicDescription-HALF img{max-width:320px;max-height:208px;}
.dynamicDescription.dynamicDescription-FULL img{max-width:658px;max-height:260px;}
.dynamicDescription.dynamicDescription-HALF.dynamicDescription-odd .col-desc{padding-left:20px;}
.dynamicDescription.dynamicDescription-HALF.dynamicDescription-even .col-desc{padding-right:20px;}

.clearfix {float: right; margin: 0 10px 0 0; width: 168px;}
.clearfix .product .delivery {clear:both; display:block; width:165px; margin-bottom:5px;}
.clearfix .rates {height:21px;white-space:nowrap;}
.clearfix span.addToCart {font-size:0px; cursor:pointer; text-indent:-2000px; background:url(/images/www/button5.png) no-repeat; display:block; height:31px; margin-top:5px; width: auto;}
.clearfix a:hover span.addToCart, a:hover span.addToCartBig, a.addToCartBig:hover{background-position:0 -31px;}

.clearfix span.priceShop span span{text-decoration: none !important; font-size: 12px; color: #ED1C24;}

.prices-box .delivery, .prices .delivery {display: none;}

/* -------------------------------------------------------------------
	uzywane do testow A/B - lifting KP i LP
---------------------------------------------------------------------*/
.logotype{font-size: 11px; font-weight: bold;}

.paymentLeft .radio-css input[type='radio'] + i  {
	margin-top:-3px;
}

/* 	Tabela danych technicznych  */


.dostepnyWSlepie{display:block;float:none;clear:both;width:156px;height:56px;}
.dostepnyWSlepie.statusDetails {
	margin-top:5px;
}
.product .dostepnyWSlepie {
	padding-top:5px;
	padding-bottom:5px;
}

#ratesInfo .desc{padding:15px;}

table.instalment{margin:0 auto;}
table.instalment th{padding:5px;font-size:13px;text-align:center;font-weight:bold;}
table.instalment td.col0{width:300px;}
table.instalment td.col1{text-align:right;padding-right:40px;min-width:66px;}
table.instalment td.col2{min-width:150px;text-align:center;}
table.instalment td.col3{white-space:nowrap;min-width:116px;}
table.instalment .kalkulator{color:#333333;text-decoration:none;display:block;width:65px;background:url(/images/www/ico-calculator.png) no-repeat left center;padding:2px 2px 2px 20px;font-weight:bold;}

table.instalment td{padding:5px;}
table.instalment td.col1, table.instalment td.col2{border-bottom:1px solid #EEEEEE;}
table.instalment th.col1, table.instalment th.col2, table.instalment th.col3, table.instalment th.col4{border-bottom:1px solid #CCCCCC;}
table.instalment .last td.col1, table.instalment .last td.col2, table.instalment .last td.col3{border-bottom:0}

.accesories .category {margin:0px; }
.accesories .shortDesc{padding:3px 0;}

.accesories .shortDesc p{display:inline}
.product .accesories h2 {color:#000; min-height:10px;}
.accesories li.inactive div.inactive {text-align:center; margin:10px 0px; font-size:11px; font-weight:bold; color:#999;}

.accesories h2 {clear:both; padding:12px 20px; font-weight: bold; font-size: 14px;}
.accesories .groups li {list-style: none;clear:none;margin:0px; padding:0px 0 0 20px; float:left;width:205px;border:none; font-size: 12px; }
.accesories .groups li.nL{clear:both;}
.shopPrices {margin-top:0px; text-align:right;}
.shopPrices .price {float:none; padding-left:0px; }

#show-product-accessories{
	width: 100%;
	max-width: 782px;
}

.accessories-wrap {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 16px;
}

.accessories-wrap .accessory-box {
	width: 226px;
}
.accessory-box .accessory-resize {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.accessory-box .accessory-resize .acc-prices {
	margin-top: auto;
}

span.text-hide {position: absolute;top:0;left:0;text-indent:-1000px; font-size: 0;}

span.status-btn-sel {background-position: 0 -20px !important;}

a.page-navigator-button:hover{text-decoration:none;}

.hoverTip{background:url("/images/www/add_info.png") no-repeat;display:inline;margin:0 0px;}

td.desc .title, td.desc .title a{color:#333333;font-weight:bold;height:34px;overflow:hidden;font-size:12px;}
td.desc .text{width:170px;margin:2px 0 0 0;padding:0;font-size:11px;height:30px;overflow:hidden;}
td.desc .link{margin:5px 0 0 0px;font-size:12px;font-weight:bold;padding:0 10px 0 0;color:#0c4da2;display:block;float:left;background: url(/images/www/li-blue-white.gif) no-repeat right center;}
.wide td.desc .text{width:280px;}


.statusError, .statusOk {
 position:relative;
 margin:0px auto;
 min-width:8em;
 max-width:760px; /* based on image dimensions - not quite consistent with drip styles yet */
 z-index:1;
 margin-left:12px; /* default, width of left corner */
 margin-bottom:0.5em; /* spacing under dialog */
}
.statusError .errorBoxContent,
.statusOk .errorBoxContent{padding-top:10px;}
.statusError span.error, .statusError span.err{margin-top:6px;color:#ED1C24;display:block;font-weight:bold;clear:both;}
.statusError .errorText{margin-top:6px;color:#ED1C24;display:block;font-weight:bold;clear:both;}
.statusOk .errorText{margin-top:6px;color:#5FB1BF;display:block;font-weight:bold;}
.statusError .w380, .statusOk .w380{width:384px}
.statusOk .err{display:none;}

.question .errorBox .info{margin:0 !important;}
.statusError .w375, .statusOk .w375{width:363px !important;}

.statusError .t, .statusOk .t {
 /* top+left vertical slice */
 position:absolute; left:0px; top:0px; width:12px; /* top slice width */
 margin-left:-12px; height:100%;
 background-position:top left;
}

.statusError .b, .statusOk .b { position:relative; width:100%;}

.statusError .b,
.statusError .b div,
.statusOk .b,
.statusOk .b div {height:10px;  font-size:1px;}

.statusError .b, .statusOk .b { background-position:bottom right;}

.ico-loading{width:16px;height:16px;background:url(/images/www/basket-loader.gif) no-repeat top left;}
.warrantyRegulations .errorText{margin-left:20px;}

.productTab{border-top:1px solid #CACACA;}
/* tu dodaje PAT */

/* Widok listowy produktów  */


/* tu koniec PAT */

/*         Zgłoś błąd       */

.errorForm h2{font-size:16px;font-weight:bold;margin:10px;text-align:left;}
.errorFormEnd h2{font-size:15px;font-weight:bold;margin:30px 0;text-align:center;}
.errorForm label{display:block;float:left;text-align:right;padding-right:10px;font-weight:bold;font-size:12px;color:#333333;}
.errorForm label.w150{width:150px;}
.orderUser{position:absolute;top:11px;right:10px;font-size:12px;color:#ffffff;}
#fancy_div ul, #fancybox-outer ul{padding:0;margin:0}
#fancy_div ul li, #fancybox-outer ul li{padding:0px 0;margin:0px 0 0px 20px;list-style-type:circle;}

.shopMoreInfo {display:none;clear:both;}
.showMoreInfo {clear:both;display:block;}
.lastShopInfo .showMoreInfo {margin-left:20px;}
.additionalShopInfo .showMoreInfo {margin-left:50px;}
.additionalShopInfo .shopMoreInfo {margin-left:50px;}

.oneItem .hidden-current-message .currentlyVisible .col-message{display:none;}
.oneItem .currentlyVisible .showMoreInfo {margin-left:20px;}
.oneItem .currentlyVisible .shopMoreInfo {margin-left:0;}
.deliveryToShopUnavailable {background: #f1f1f1; color: #858585;}
.collectionDate {font-size:18px!important;font-weight:bold;margin-bottom:5px;}
.shopsItem{clear:both;float:none;padding:10px;}
.selectShop .shopsItem{padding:10px 20px 15px;}
.shopsItem h3 {font-size:18px;padding:0;margin-bottom:5px;}
.shopsItem .shop-code {font-weight: bold; padding:3px; background-color:#FFFFCC; margin-bottom:10px; max-width:265px;display:none;}
.operator-logged .shopsItem .shop-code, .operator-logged #shops-modal #shops-list .shop-code{display:block;}
.shopsItem div{font-size:11px;}
.selectShop .shopsItem div{font-size:12px;}
.shopsItem div.col{float:left;}
#unityShopName{color:grey;width: 785px; margin-left: 20px;}
.shopsItem div.checkbox{width:30px;padding-left:20px}
.shopsItem div.col1{width:300px;min-height:1px}
.changeShopAndDate {display: none;padding:0 10px;}
.oneItem .changeShopAndDate {display: inline-block; margin-top: 10px;}
.changeShopAndDate div.col1{width:300px;margin-left:20px;float:left;}
#unity-shop-chooser.loading{min-height:80px !important;}
#unity-shop-chooser.oneItem .changeShopAndDate {padding:0 10px 10px 0;}
#pofficeList .shopsItem div.col1{width:600px}
.shopsItem div.col2{width:150px}
.selectShop .shopsItem div.col2{width:245px}
.shopsItem div.col3{width:130px}
.selectShop .shopsItem div.col3{width:345px}
.unity-shop-cities .shopsItem div.col3{font-weight:bold;}
#stores-list #unity-shop-cities{clear:both;width:auto;}
#inpostPack .errorBox.statusOk{display: none;}
.selectCity .left{margin:7px 0 14px 0;float:left;width:480px;height:360px;}
.selectCity .right{margin:7px 0 14px 0;float:right;width:410px;}
.selectCity #shopsMap{margin:5px auto;width:350px;height:350px;}
.selectCity #shopsMap object{outline:none;}
.selectCity .selectCityList, #selectCityText{width:280px;}
.selectCity .selectCityList a{text-decoration:none;position:relative;color:#333333;font-size:12px;display:block;padding:5px 0 5px 10px;}

.selectCity .selectCityList .row1{background:#f0f0f0;}
.selectCity .selectCityList .row2{background:#f9f9f9;}
.selectCity .selectCityList	a:hover .city{text-decoration:underline;}

.selectCity #citiesArea .selectCityList .activ {background:#CFE7FF;color:#12395F;}
.selectCity #citiesArea .selectCityList .activ .city{font-weight:bold;}

.selectCity .selectCityList	.count{position:absolute;top:5px;right:0;width:20px;text-align:center;}

.swf object{outline:none;}

.operator-message ~ #emailRow.email-invalid .errorBox:not(.statusError) .email-invalid-message {padding-left: 150px;}

/*tryb partnerski*/
#partner-bar .fix{padding:5px;position:fixed;top:0;width:100%;z-index:100;}
#partner-bar .body{margin:auto;width:960px;}
.statusError .w465, .statusOk .w465{width:454px;}

/* --------------------------------------------------------------------
	Gorna i dolna czesc strony (top, menu , pasek nawigacyjny, footer)
-------------------------------------------------------------------- */


#printButton{display:none;}

/*-------------- zbiorcza klasa CONTENT -------------*/

/* ---GĂ“RA I DĂ“Ĺ� CENTER CONTENTA--- */
.menu-one-column .left-bg, .menu-one-column .right-bg {width: 140px !important;}


#onlyAvailableBox{margin-top:10px;}

.special-hours-box {display: table;width:100%;}
.special-working-hours{color: #ED1C24;padding:5px 10px 10px 50px;clear:both;}
.oneItem .special-working-hours {padding-left:20px;}
.oneItem .col1 .special-working-hours {padding-left: 0;}
.lastChoice .special-working-hours {padding-left:50px;}
.special-data-container{float:left;width:50%;overflow: hidden;}
.date-description{float:left;width:50%;}
.hours-description{float:right;width:50%;}
.special-working-hours-header{font-weight:bold;padding-bottom:10px;}

.home-flat-row{}
.home-flat-row .row{float: left;width: 50%;}
.home-flat-row .row label.lab{float:left;}
.home-flat-row .row:first-child label.lab{float:left;margin-left: 18px;}
.home-flat-row .row .input-bg-90{float:left}
.home-flat-row .row span.error{padding-left: 131px;padding-top: 6px;}
.home-flat-row .row:first-child span.error{padding-left: 149px;}
.home-flat-row .row:first-child .errorBox{margin-right: 0 !important;}
.customer .home-flat-row .row label.lab{margin-left: 23px;}
.customer .home-flat-row .row .statusError label.lab,
.customer .home-flat-row .row .statusOk label.lab{margin-left: 11px;}
.customer .home-flat-row .row:first-child span.error{padding-left: 142px;}
.customer .home-flat-row .row:nth-child(2) {width:44%;}
.customer .home-flat-row .row:nth-child(2) label.lab{margin-left: 0}

a.company {color: #000;}

#tooltip {position:absolute;z-index:3000;opacity:0.92;width:250px;border:none;}
#tooltip div { margin: 0;}
#tooltip .head{
	font-size:12px;background:#FFF;-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 9px;
	-webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
	-moz-box-shadow:    0px 0px 7px 0px rgba(50, 50, 50, 0.4);
	box-shadow:         0px 0px 7px 0px rgba(50, 50, 50, 0.4);
	border:solid 1px #c4c4c4;
}

#tooltip.QiA{opacity:1;width:auto;max-width:400px;}
#tooltip.QiA .body{font-size:12px;height:auto;padding:5px;color:#222222;background:#eeeeee;border:1px solid #dddddd;border-radius:3px;box-shadow:0 0 1px #777777;}
#tooltip.QiA .url{display:none;}

#tooltip.conformity-tip{width:auto;}
#tooltip.conformity-tip h3{display:block !important;position:relative;}
#tooltip.conformity-tip .body{height:auto;min-height:14px;padding:0 2px 20px 20px;}
#tooltip.conformity-tip h3 .auto{width:17px;height:17px;top:0px;right:-17px;position:absolute;background:url(/images/www/tooltip.png) no-repeat top right;}
#tooltip.conformity-tip .body .auto{width:17px;height:34px;bottom:0px;right:-17px;position:absolute;background:url(/images/www/tooltip.png) no-repeat bottom right;}

.smallLoading{background:url(/images/www/basket-loader.gif) no-repeat center center;width:16px;height:16px;}

.ico-produktNiedostepnyTop{background:url(/images/www/ico-produktNiedostepny.gif) no-repeat center top;width:100%;height:60px;}
.ico-produktNieaktywnyTop{background:url(/images/www/ico-produktNieaktywny.gif) no-repeat center top;width:100%;height:60px;}

.ico-produktNiedostepnyBottom{background:url(/images/www/ico-produktNiedostepny.gif) no-repeat center -60px;width:100%;height:75px;}
.ico-produktNieaktywnyBottom{background:url(/images/www/ico-produktNieaktywny.gif) no-repeat center -58px;width:100%;height:75px;}

.questionsLink{margin-bottom:5px;display:none;}

.warrantyList .item{padding:15px;}
.warrantyList .image{text-align:center;vertical-align:middle;width:80px;height:80px;margin:0 auto;}
.wL1 .image{float:left;}
.wL1 .tekst{margin:10px;float:left;width:400px;}
.wL1 .more{display:none;}

.wL2 .item{float:left;width:309px;text-align:center;}
.wL2 .item1{border-right:1px solid #CACACA ;width:308px;}
.wL2 .tekst, .wL3 .tekst{text-align:center;margin-top:10px;}
.wL2 .cena, .wL3 .cena{text-align:center;margin-top:5px;}
.wL2 .infoPrice, .wL3 .infoPrice{display:block;margin-top:2px;}
.wL2 .infoPrice span, .wL3 .infoPrice span{display:block}

.wL3 .item{float:left;width:195px;text-align:center;border-right:1px solid #CACACA ;}
.wL3 .item3{border-right:0;}

.inpostItem div.col1 {width:600px;}

.accessSearch{position:relative;width:196px;height:48px;background:url(/images/www/accessoriesSearch.png) no-repeat 0 0;margin:0 0 10px 0;padding:3px 5px 0}
.accessSearch .text{height:22px;overflow:hidden;width:196px;font-weight:bold;font-size:12px;line-height: 19px;}
.accessSearch .text span{font-size: 15px;color:#285BB7;margin:0px 5px;}
.accessSearch select, .accessSearch input{width:194px;border:1px solid #CCCCCC;font-size:11px;padding:2px 0;line-height:20px;color: #000000;}

.pokazProdukty3{margin-left:42px;display:block;width:110px;height:20px;background:url(/images/www/button12.png) no-repeat 0 0;}
.pokazProdukty4{float:left;display:block;width:55px;height:20px;background:url(/images/www/button12.png) no-repeat -110px 0;}



.fancyDictDiv{float:left;width: 198px;padding:5px 0 0 5px;}
#left-column .yellow .left-box-center .fancyDictDiv a{margin-left:0;float:none !important;width:auto;display:inline !important;}
#left-column .yellow .left-box-center .fancyDictDiv a.fancyDict{position:relative;top:-2px;}

#lastViewedProduct{display:none;}
#lastViewedProduct .lazy{width:71px;height:63px;}

.errorBox label.error{top:-4px;position:relative;font-size:11px;margin-left:5px;float:none;display:inline;color:red;font-weight:bold;padding:3px 0 2px 0}
.errorBox label.valid{float:left;width:24px;height:20px;padding:0;display:block;}
.errorBox label.bigError, #shopCodeError label.error{color:red;min-height:20px;font-weight:bold;overflow:auto;font-size:12px;width:auto;height:auto;text-align:center;display:block !important}
.leftValidError .errorBox label.valid, .leftValidError .errorBox label.error{float:right;width:140px;background-position:right 0;margin-right:5px;margin-left:0}
.leftValidError  label img{float:right;}
#selectShopDiv .errorBox label.valid{display:none !important;}
#selectShopDiv, #shopCodeError {position: relative}
#selectShopDiv {z-index: 1}
#shopCodeError {z-index: 0}
.check_zgoda label.error{margin-left:122px;}
#shopCodeError label.valid{display:none !important;}

#toolPgr{position:absolute;width:389px;visibility: hidden;z-index:1110;}
#toolPgr.tooltip-small{ width: auto; }
#toolPgr.limit-width{ width: 370px; }
#toolPgr .container{position:relative;}
#toolPgr.t .ico{background-position:-28px bottom;top:-6px;}
#toolPgr .title{color:blue;font-weight:bold;padding-bottom:5px;}
#toolPgr ul{margin-left:20px;}
#toolPgr ul li{list-style:disc outside;}
#mainPageTopBanerL img{display:block;}


#attributesHidden {background: #E2E2E2;height: 26px;opacity: 0.8;position: absolute;top: 22px;width: 200px;left:1px}
#attributesBox .fleft{margin:0 5px 0 6px;width:85px;}
#attributesBox .fleft input{float:left;}

.szybkaRezerwacjaMessage, .szybkaRezerwacjaMessage div{font-family:Arial;font-size:12px;font-weight:bold;color:#ed1c24;text-align:center;}
.total .szybkaRezerwacjaMessage, .summary .szybkaRezerwacjaMessage, .customer .szybkaRezerwacjaMessage{text-align:left;}
#basket-small .szybkaRezerwacjaMessage{display:inline;}

.minH .boxSpacer.rolledOut > .w185 {width: auto;}

#products-list .szybkaRezerwacjaMessage, #products-list .szybkaRezerwacja{margin-top:5px;}
.product .szybkaRezerwacjaMessage,.product .szybkaRezerwacja{margin-top:5px;}


#content-2c > .banners{overflow: hidden;width: 680px;}
.content > .banners{overflow: hidden;width:940px;}

.banners .banner-link{margin:0 auto;}
.banners .banner-photo{text-align: center;}
.banners .banner-photo a{margin:0 auto;}

/*popup gazetki*/
#personPopupContainer{    max-width:400px;position:absolute;    left:0;    top:0;    display:none;    z-index: 20000;}

#personPopupContent{    background-color: #FFF;    min-width: 175px;    min-height: 50px;}
.personPopupPopup .personPopupImage{    margin: 5px;    margin-right: 15px;}
.personPopupPopup .corner {    width: 12px;    height: 10px;}
.personPopupPopup .topLeft {    background: url(/images/www/tip/balloon_topLeft.png) no-repeat;}
.personPopupPopup .bottomLeft {    height:14px;background: url(/images/www/tip/balloon_bottomLeft.png) no-repeat;}
.personPopupPopup .left {    background: url(/images/www/tip/balloon_left.png) repeat-y;}
.personPopupPopup .right {    background: url(/images/www/tip/balloon_right.png) repeat-y;}
.personPopupPopup .topRight {    background: url(/images/www/tip/balloon_topRight.png) no-repeat;}
.personPopupPopup .bottomRight {    height:14px;background: url(/images/www/tip/balloon_bottomRight.png) no-repeat;}
.personPopupPopup .top {    background: url(/images/www/tip/balloon_top.png) repeat-x; }
.personPopupPopup .bottom {    background: url(/images/www/tip/balloon_bottom.png) repeat-x;text-align: center;}

/*NOWE OPINIE*/

#question-paging{margin-top:10px;}

#cms .page .content{padding-top:10px;}
/*KONIEC OPINII*/

.ordersRequiringActions{padding:5px 0 0 30px;background:url(/images/www/bg-ordersRequiringActions.png) no-repeat -2px -2px;}
.ordersRequiringActions ul{margin:7px 0 0 5px}
.ordersRequiringActions ul li,
#instalmentOrdersRequiringActions .ordersRequiringActions ul li{clear:both;float:none;display:block;padding:0 0 7px 0;margin-left:0;list-style:none;}
.ordersRequiringActions .number, .ordersRequiringActions a, .ordersRequiringActions .ball{float:left;}
.ordersRequiringActions a.button{margin:-5px 0 0 5px;}

.deliveryCityForm{width:380px;padding:10px;}
.deliveryCityForm label{margin:2px 5px 0 0;display:block;text-align:right;float:right;}
.deliveryCityForm .fright{width:200px;}
.deliveryCityForm .title{margin-bottom:10px;font-size:14px;font-weight:bold;}
.deliveryCityForm .row{padding:5px 0 0;}

.offertItem{border:1px solid #cccccc;border-radius:3px;margin-bottom:10px;padding:10px;color:#222222;}

.offertItem h2{text-align:center;font-size:14px;margin-bottom:5px;}

.timeDeliveryList .dayList{display:none;}
#fancybox-outer .timeDeliveryList ul.day{margin:0;padding:0;margin-right:-10px;}
#fancybox-outer .timeDeliveryList li.group,
.timeDeliveryList li.group{margin:0;display:block;float:left;width:205px;background:#eeeeee;padding:10px;margin:10px 10px 0 0;list-style:none;}
#fancybox-outer .timeDeliveryList ul.group{margin:10px 0 0;}
#fancybox-outer .timeDeliveryList li.newRow,
.timeDeliveryList li.newRow{clear:both;}
#fancybox-outer .timeDeliveryList .title,
.timeDeliveryList .title{font-size:13px;font-weight:bold;padding-bottom:8px;}
#fancybox-outer .timeDeliveryList label,
 .timeDeliveryList label{display:inline-block;min-width:50px;font-weight:bold;font-size:13px;margin:0 10px;}
#fancybox-outer .timeDeliveryList .price,
.timeDeliveryList .price{font-weight:normal;font-size:12px;vertical-align: super;}
#loadTimeAndServicesList .timeDeliveryList label.radio-css {
	margin: 0;
	min-width: auto;
}

#instalmentOrdersRequiringActions{width:350px;}
#instalmentOrdersRequiringActions .ordersRequiringActions ul{margin:15px 0 10px 5px;list-style:none;}
#instalmentOrdersRequiringActions table{margin:10px 0;border-collapse: collapse;border-top:1px solid #cacaca;border-left:1px solid #cacaca;}
#instalmentOrdersRequiringActions table td,#instalmentOrdersRequiringActions table th{border-bottom:1px solid #cacaca;border-right:1px solid #cacaca;padding:5px;}

.promotionPriceDates{font-size:8px;font-weight:bold;margin:0;}
.promotionPriceDates span{display:block;}

/*Facebook openGraph*/
.oGcontainer{margin:5px 0px; padding: 0px 5px;display:none;}
.oGcontainer.oGmultimedia{margin:0px;}
.oGmultimedia{right:0px;border-left:1px solid silver;border-right:1px solid silver;background: #fff;}
.oGcontainerRight{padding:5px;float:right;}
.oGcontainer .clear2{overflow:hidden;clear:both;}
.oGcontainer .oGbutton{margin-right:5px;float:left;padding:0px 5px 3px 5px;background:#eceef5;border:1px solid #cad4e7;cursor:pointer;white-space:nowrap;color:#3b5998;border-radius:3px;-moz-border-radius:3px; /* Old Firefox */}
.oGcontainer .oGbutton:hover{border-color: #9DACCE;}
.oGcontainer .oGbutton div{margin-top:-1px;}
.oGcontainer .oGbutton a{color:#3b5998;line-height:11px;text-decoration:none;}
.oGcontainer .oGbutton a .sp_like{background-image: url(/upload_module/graphics/_fb.png);background-repeat: no-repeat;display: inline-block;height: 14px;width: 14px;margin-right: 2px;position:relative;top:3px;}

#nick-status{width:30px;float:left;height:20px;background:no-repeat center center;}
#nick-status.loading{background-image:url(/images/www/basket-loader.gif)}

/* tooltip3 */
#tooltip.tooltip3 {position:absolute;z-index:3000;opacity:0.92;width:auto;border:1px solid #ccc;border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;background:#fff;}
#tooltip.tooltip3 h3, #tooltip.tooltip3 div { margin: 0;}
#tooltip.tooltip3 h3{font-size:12px;background: none;padding:5px;}
#tooltip.tooltip3 .body{display:none !important;background:none;}

#deleteForm {width:350px;}
#deleteForm .message{font-weight:bold;font-size:14px;text-align:center;}
#deleteForm .buttons{margin-top:10px;}

.livechat-mini{float:left;clear:left;margin:7px 0;color:black;}
.livechat-text{font-weight:bold;line-height:5px;font-size:13px;vertical-align:text-top;}
.lcIcon{display:block;float:left;}
.lcIcon.lch_16{height:16px;line-height:16px;}
.lcIcon.lch_18{height:18px;line-height:18px;}
.lcIcon.lc_16{background: url(//f00.esfr.pl/si_upload/unity/files/16x16_lc.png) no-repeat scroll 0 0;}
.lcIcon.tel1_16{background: url(//f00.esfr.pl/si_upload/unity/files/16x16_lc.png) no-repeat scroll 0 -34px;}
.lcIcon.tel2_16{background: url(//f00.esfr.pl/si_upload/unity/files/16x16_lc.png) no-repeat scroll 0 -52px;}
.lcIcon.lc_18{background: url(//f00.esfr.pl/si_upload/unity/files/18x18_lc.png) no-repeat scroll 0 0;}
.lcIcon.tel1_18{background: url(//f00.esfr.pl/si_upload/unity/files/18x18_lc.png) no-repeat scroll 0 -39px;}
.lcIcon.tel2_18{background: url(//f00.esfr.pl/si_upload/unity/files/18x18_lc.png) no-repeat scroll 0 -58px;}
span.voucher-icon {float:right;}

.service-item .service-name { font-weight: bold; }
.service-item .service-name a { font-size: 15px; color: #333333; }

.zip-code-validation {
	padding-left: 45px;
	color: #ED1C24;
	padding-top:3px;
	font-weight:bold;
}
.zip-code-validation-hidden{display:none;}
.response-text {
	padding: 10px 0 10px 30px;
	font-weight: bold;
}

.address-autocomplete {
	display: none;
}

.address-autocomplete .input-bg-185 {
	width: 349px;
}

.error-box div[class^='input-bg'] {
	border: 1px solid #ff0000;
}

.autocomplete-map {
	width: 350px;
	margin-top: 10px;
	height: 200px;
}

.tracking-info {
  display: table;
  margin: 5px 0;
}

.tracking-info > div {
  display: table-cell;
  vertical-align: middle;
  padding-right: 6px;
}

.tracking-image {
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.tracking-image img {
  display: block;
  width: 30px;
}