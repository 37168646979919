@mixin border-no-bottom($border-color, $border-width:1px) {
	border: $border-width solid $border-color;
	border-bottom: 0;
	border-radius: 0;
}

@mixin border-no-top($border-color, $border-width:1px) {
	border: $border-width solid $border-color;
	border-top: 0;
	border-radius: 0;
}

@mixin border-left-right($border-color, $border-width:1px) {
	border-left: $border-width solid $border-color;
	border-right: 1px solid $border-color;
	border-radius: 0;
}

@mixin border-no-top-bottom($border-color, $border-width:1px) {
	border: $border-width solid $border-color;
	border-top: 0;
	border-bottom: 0;
	border-radius: 0;
}

@mixin flat-full-border($border-color, $border-width:1px) {
	border: $border-width solid $border-color;
	border-radius: 0;
}

.flat-color1 {
	background: #cbcbcb; // szary
}

.flat-color2 {
	background: #6e6e6e; // czarny
}

.flat-color3 {
	background: #fff; // biały
}

.flat-color4 {
	background: #ffe843; // żółty
}

.flat-aBox {
	@include border-no-top-bottom($border-color);
	background: #f3f3f3;

	.t2 {
		@extend .flat-color3;
		border-top: 1px solid $border-color;
	}

	.nn {
		@extend .flat-color3;
		border-bottom: 1px solid $border-color;
	}
}

.flat-aBox-top {
	border-top: 1px solid $border-color;
	background: #f3f3f3;
}

.flat-aBox-bottom {
	border-bottom: 1px solid $border-color;
	background: #f3f3f3;
}

#content-2c .center-top-bar.black {
	font-size:0; height:26px; @include border-no-bottom($border-color); @extend .flat-color2;
}

#moje-konto #left-column .grey .left-box-bar {background: url(/images/www/bg-mojeKontoBar.png) 0 0;height:43px;}
#content-1c .center-bottom{width:940px; height: 8px; font-size:0; box-sizing: border-box; @include border-no-top($border-color);}

#content-2c .center-top-bar.grey, #bottomPomoc .left-box-bar2{padding:8px 0 0 10px; height:24px; @include border-no-bottom($border-color); @extend .flat-color1; text-align:center;}
#content-2c .center-bottom, #bottomPomoc .left-box-bottom{height: 9px; font-size:0; margin-bottom: 5px; @extend .flat-color3; @include border-no-top($border-color);}
#content-2c .center-top, .content-2c .center-top {height: 5px; font-size:0; @extend .flat-color3; @include border-no-bottom($border-color);}
#moje-konto #content-2c .center-top-bar.grey{@include border-no-bottom($border-color); @extend .flat-color1; height:35px;}

.accountBox{width:326px; @include flat-full-border($border-color);}
.accountBoxTop .top{height:5px; @extend .flat-color3; @include border-no-bottom($border-color);}
.accountBox .b{width:305px;padding:4px 10px 10px}
.accountBox .t{padding-top:5px;}
.accountBoxTop .bottom{height:5px; margin-bottom: 5px; @extend .flat-color3; @include border-no-top($border-color);}
.accountBoxTop .middle{border-left:1px solid $border-color;padding:3px 10px 4px;border-right:1px solid $border-color;}

.aBox{margin:5px 13px 15px;display:none;width:651px; @extend .flat-aBox;}
.aBox .tt{height:8px;font-size:0px; @extend .flat-aBox-top;}
.aBox .bb{height:5px;font-size:0px; @extend .flat-aBox-bottom;}
.aBox .nn{padding:0 10px 7px;}
.aBox .t2{height:8px;font-size:0px;}

.customer .menu a{display:block; @extend .flat-color2; padding:0 10px;border:1px solid #555555;border-bottom:0;text-decoration:none;}
.customer .menu li+li a {border-left: 0;}

#left-column .dgrey .left-box-bar {@extend .flat-color2; height:36px;}
#left-column div.dgrey .left-box-bottom {@extend .flat-color2; font-size:0;height:2px;}

.content #footer-boxes {height: 240px; @extend .flat-color3; background-position: 0 0;
	position: relative;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;

	&:before, &:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		width: 1px;
		background: $border-color;
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}
}

.content {background:#e7e7e7; border-left: 1px solid #d9d9d9;border-right: 1px solid #d9d9d9; padding: 0 9px;}
.content #footer-boxes .footer-box .footer-box-top {@extend .flat-color1; padding: 10px 0; margin:0px 0 0; font-size:13px;font-weight:bold; text-align: center;}
.content #footer-boxes .footer-box.first {@extend .flat-color3;}
.content #footer-boxes .footer-box.last {float:right; @extend .flat-color3; border: 0 none;}

.voucherA, .voucherW {
	padding: 5px 8px;
	color: #fff;
	text-transform: uppercase;
	border-radius: 10px;
	font-size: 10px;
	font-weight: 700;
	display: inline-block;
}

.voucherW {
	background: #ed1c24;
}

.voucherA {
	background: #40be4c;
}


.main-page {
	#container {
		padding-bottom: 0;
	}

	.content {
		padding-bottom: 10px;
	}
}

#container {
	margin-bottom: 10px;
}

#left-column .grey .left-box-bar2{position:relative;  height:36px; @extend .flat-color1;}
#left-column div.dgrey .left-box-bar {@extend .flat-color2; height:36px;}
#content-2c #left-column .left-box-bottom{font-size:0; height: 3px; @extend .flat-color3; @include border-no-top($border-color);}
.content-corners-bottom {background: $border-color; height: 1px; font-size: 0;}
#mainPageTopBanerL > div{width:250px;height:300px;overflow:hidden;border:1px solid $border-color;background-color:#ffffff;}
#mainPageRotator{border:1px solid $border-color;overflow:hidden;background:url(/images/www/basket-loader.gif) #ffffff no-repeat 330px 130px;}

a.ratesInfo, a.serviceInfo, a.paymentInfo {
	position:relative;text-decoration:none;
	display: inline-block;
	width: 12px;
	height: 12px;
	line-height: 12px;
	text-align: center;
	margin: 0 1px;
	border: 1px solid #d7d7d7;
	border-radius: 50%;
	font-weight: 500;
	text-indent: 0;

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		color: #e81b22;
		font-size: 11px;
		content: '?';
	}
}

#toolPgr .top {
	background: #fff; padding:13px 10px 6px;
	border: 1px solid $border-color;
	border-bottom: 0;
}

#toolPgr .bottom {
	height:10px;
	position:relative;
	&::before{
		@extend .flat-color3;
		height:2px;
		display:block;
		box-sizing: border-box;
		width:100%;
		content:"";
		@include border-no-top($border-color);
	}
}

#toolPgr {
	.ico {
		cursor:pointer;
		position:absolute;
		bottom:0px;
		left:20px;
		width:16px;
		height:8px;
		margin-left: -3px;

		&:before {
			content: "";
			position: absolute;
			left:0;
			top:0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 8px 0 8px;
			border-color: #cccccc transparent transparent transparent;
		}

		&:after {
			content: "";
			position: absolute;
			left:1px;
			top:-1px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 7px 0 7px;
			border-color: #ffffff transparent transparent transparent;
		}
	}

	&.t {
		.ico {
			&:before {
				border-width: 0 8px 8px 8px;
				border-color: transparent transparent #cccccc transparent;
				top: -1px;
			}

			&:after {
				border-width: 0 8px 7px 7px;
				border-color: transparent transparent #ffffff transparent;
				top: 0px;
			}
		}
	}
}

.content-corners-top {background: #e7e7e7; height: 10px; font-size: 0; border: 1px solid #d9d9d9; border-bottom: 0;}

#left-column .yellow .left-box-bar {height:36px; @extend .flat-color4;}
#left-column .yellow .left-box-center {background:#E7EDF5; border-left: 2px solid #000;  border-right: 2px solid #000;border-bottom: 2px solid #000;}
#left-column .yellow .left-box-bottom {font-size:0; @extend .flat-color4; height:0;}
#left-column .yellow .left-box-center a:hover {background-color:#EFF6FF;}

.content-2c .center-bottom{height: 9px; font-size:0; @include border-no-top($border-color); position:relative;top:-5px; @extend .flat-color3;}

#content-1c .center-top{width:940px; height: 5px; font-size:0; box-sizing: border-box; @include border-no-bottom($border-color); @extend .flat-color3;}
#content-1c .center-bottom2{margin-bottom:3px;width:940px; height: 5px; font-size:0; box-sizing: border-box; @include border-no-top($border-color); @extend .flat-color3;}

.accountBoxLarge .topBorder{height:5px; @include border-no-bottom(#e5e5e5);}
.accountBoxLarge .boxContent{padding:4px 10px 10px;font-size:inherit;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;}
.accountBoxLarge .bottomBorder{height:5px; @include border-no-top(#e5e5e5);}

.paymentRight .top{@include border-no-bottom($border-color);height:7px;font-size:0px; box-sizing: border-box; background: #f6f6f6;}
.paymentRight .text{@include border-left-right($border-color);height:auto;min-height:220px;padding:0px 15px 0px 10px;line-height:18px; box-sizing: border-box; background: #f6f6f6;}
.paymentRight .bottom{@include border-no-top($border-color);height:11px;font-size:0px; box-sizing: border-box; background: #f6f6f6; margin-bottom: 10px;}

.opinion-statistics .top{height:2px;@include border-no-bottom(#cfcfcf);}
.opinion-statistics .table {
	position: relative;border-left:1px solid #cfcfcf;border-right:1px solid #cfcfcf;
	&:after {
		content: "";
		position: absolute;
		left:0;
		right:0;
		top:0;
		bottom:0;
		width: 1px;
		margin:auto;
		background: #cfcfcf;
	}
}

.opinion-statistics .bottom{position:relative;top:-5px;height:5px;@include border-no-top(#cfcfcf);}

#opinion-list .item{margin-top:10px;position:relative;border: 1px solid #CACACA}

.simple-menu li.current,#fancybox-outer .simple-menu ul li.current {@extend .flat-color3; border-top: 1px solid #ccc; position: relative;}

.paymentLeft div.activ .rog{
	display:block;position:absolute;top:7px;right:-1px;width:6px;height:11px;

	&:before {
		content: "";
		position: absolute;
		left:0;
		top:0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5.5px 6px 5.5px 0;
		border-color: transparent #ccc transparent transparent;
	}

	&:after {
		content: "";
		position: absolute;
		left:1px;
		top:1px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 4.5px 6px 4.5px 0;
		border-color: transparent #f6f6f6 transparent transparent;

	}
}

.bBfO{margin:0 0 10px 0 !important;padding:10px 0 0 30px;border-top:1px solid #cccccc;}

#order_path	li {
	position: relative;

	>a >span,
	>span {
		position: relative;
		z-index: 1;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 16px;
		right: 0;
		left: 0;
		height: 1px;
		background: #d6d6d6;
	}

	&:after {
		content: "";
		position: absolute;
		top: 5px;
		right: 0;
		left: 0;
		margin: auto;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background: #c4c4c4;
	}

	&.completed {
		&:after {
			background: #319948;
		}
	}

	&.activ {
		&:after {
			top: 0;
			width: 32px;
			height: 32px;
			background: #6b6b6b;
		}
	}

	&.firstcompleted,
	&.first {
		&:before {
			left: 50%;
		}
	}

	&.lastcompleted,
	&.last {
		&:before {
			right: 50%;
		}
	}
}

#order_path	li span.l {
	display:block;width:auto;color:#ffffff;padding:9px 0 9px;margin-bottom:2px;font-weight:bold;font-size:12px;
	line-height: 13px;
}

#order_path	li span.text{padding-top:3px}

#left-column .grey .left-box-center {
	background:#fff; @include border-no-top(#CACACA);

	&+.bT {
		border-top: 0 !important;
	}
}

.simple-menu li a {color:#ffffff; border-left:1px solid $border-color; display:block; height:30px; padding: 0px 0px 0px 9px;}
.simple-menu li span {color:#d3d3d3; font-size:13px; display:block; border-right: 1px solid $border-color; height: 23px; padding-top:7px; padding-right:9px;}
.simple-menu li+li a {border-left: 0;}
.simple-menu ul li, #fancybox-outer .simple-menu ul li{ list-style:none; padding:0px; display:block; float:left; margin:0 0 0 0; white-space:nowrap; @extend .flat-color2; font-weight:bold; text-align:center; display:block; float:left; height:30px;}

.answer .answer-comments{padding:24px 10px 10px 14px;display:none;background:#F6F6F6;border:1px solid #cacaca;margin: 11px 62px 0 0;}

#opinions .vote_bottom{position:absolute;left:-1px;bottom:0;width:680px;height:7px;}
#opinions .vote_top{position:absolute;top:0;left:-1px;width:680px;height:5px;}

#detailedCart .trProduct td{color:#333333;padding-top:12px;font-weight:bold;border-top:1px solid #dddddd;}

.paymentLeft div.activ {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 60px;
		right: 0;
		top: 12px;
		height: 1px;
		background: #a3a3a3;
	}

	>* {
		position: relative;
		z-index: 1;
	}
}

#paymentForm .paymentLeft div.activ:before {
	top: 6px;
}

.cart_summarize	tr th:first-child,
.cart_summarize	tr td:first-child {
	border-left: 0;
}

.bg-blue {background:#e7edf5;}

#left-column .dgrey .left-box-center {background:#ffffff;border-left:2px solid #6e6e6e;border-right:2px solid #6e6e6e;}

.page-navigator-dots {float:left; font-weight: bold; margin: 0 7px 0 5px;}

#attributeGrade .graph div{height:8px;background:#efcb39;}

.yellow-box-top {height:2px; background: #FFE01D;}
.yellow-box-bottom{height:2px; background: #FFE01D;margin-bottom:15px;}

.left-box-top-transparent{position:absolute;top:0;left:0;width:100%;height:10px;}
.left-box-bottom-transparent{position:absolute;bottom:0;left:0;width:100%;height:10px;}

#facebook-box .fb-left-box-top{width:100%;height:5px;background:#EDEFF4; @include border-no-bottom($border-color);box-sizing: border-box;}
#facebook-box .fb-left-box-bottom{width:100%;height:8px;background:#FFF; @include border-no-top($border-color);box-sizing: border-box;}

#newsletter-box .more{
	color: #9dafd1;padding-right: 10px;

	&:after {
		content: "";
		display: inline-block;
		margin-left: 5px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 4px 0 4px 4px;
		border-color: transparent transparent transparent #829ad5;
	}
}

.art_list {
	.paging{
		background:#F5F5F5;height:20px;padding:13px 10px;border-bottom:1px solid #E1E1E1; width: 100%; box-sizing: border-box; overflow: auto;

		.select3-container.theme0 {background: #fff; width: 70px;}
	}

	.bottomPaging {
		width: auto !important;
	}

	.page-navigator {
		margin-left: 3px; height: 32px; width: auto !important;

		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			height: 32px;
		}

		>a {
			display: inline-block;
			vertical-align: middle;
			float: none;

			>span {
				margin-left: 0px;
				margin-right: 0px;
			}
		}
	}
}
.art_list .filters label, .art_list .paging label{margin-right:10px;line-height:32px;font-weight:bold;}

.white-box-top{width:100%;height:5px;background:#fff; @include border-no-bottom(#cacaca);box-sizing: border-box;}
.white-box-bottom{width:100%;height:8px;background:#FFF; @include border-no-top(#cacaca);box-sizing: border-box;}

.art_bottom{border-top: 1px solid $border-color; height:4px;margin-left:1px;margin-bottom:6px;clear:both;}

.article-paging-bottom .bottomPaging {
	margin-left: auto !important;
	text-align: center;

	.page-navigator {
		float: none !important;
		display: inline-block;
	}
}

#left-column .dgrey .left-box-center {background:#ffffff;border-left:2px solid #6e6e6e;border-right:2px solid #6e6e6e;}

#passwordBox #passwordForm {
	margin-top: 15px;
}

.statusError .cont,
.statusError .t,
.statusError .b,
.statusError .b div {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0.2;
		background: #ff0000;
		margin-left: -7px;
	}

	&>* {
		position: relative;
		z-index: 1;
	}
}

body .statusError .b div,
body .statusOk .b div {
	position:relative; width:12px;  margin-left:-12px; background-position:bottom left;

	&:before {
		width: 0;
	}
}

body .statusError {
	.textarea,
	.input-bg-27,
	.input-bg-35,
	.input-bg-45,
	.input-bg-90D,
	.input-bg-90,
	.input-bg-147,
	.input-bg-158,
	.input-bg-185,
	.input-bg-158D,
	.input-bg-197,
	.input-bg-197B,
	.input-bg-570,
	.input-bg-540,
	.input-bg-337,
	.input-bg-158,
	.textInput {
		border: 1px solid #ff0000;
		border-color: #ff0000;
	}

	.info{
		width:9px;height:17px;display:block;position:absolute;right:13px;top:9px;
	}
}

body .statusError {
	textarea {
		border: 1px solid #ff0000;
	}

	.textarea > textarea {
		border: 0;
	}

	.checkbox-css i {
		border: 1px solid #ff0000;
	}
}

.statusOk .cont,
.statusOk .t,
.statusOk .b,
.statusOk .b div {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0.2;
		background: #aee0ea;
		margin-left: -7px;
	}

	&>* {
		position: relative;
		z-index: 1;
	}
}

body #newOpinionForm .statusOk,
body .statusOk {
	.textarea,
	.input-bg-27,
	.input-bg-35,
	.input-bg-45,
	.input-bg-90D,
	.input-bg-90,
	.input-bg-147,
	.input-bg-158,
	.input-bg-185,
	.input-bg-158D,
	.input-bg-197,
	.input-bg-197B,
	.input-bg-570,
	.input-bg-540,
	.input-bg-337,
	.input-bg-158,
	.textInput {
		border: 1px solid #aee0ea;
		border-color: #aee0ea;
	}

	.info{
		width:9px;height:17px;display:block;position:absolute;right:13px;top:9px;
	}
}

body .statusOk {
	textarea {
		border: 1px solid #aee0ea;
	}

	.textarea > textarea {
		border: 0;
	}
}

.statusError .cont, .statusOk .cont {position:relative; padding:0px 0px 0px 0px;}

div.errorBox.statusError,
div.errorBox.statusOk {
	margin-top: -10px;
}

#questionnaire,
.aBox {
	div.errorBox.statusError,
	div.errorBox.statusOk {
		margin-top: 0px;
	}
}

#questionnaire ul li .checkbox-css {
	float: left;
}

.customer .receipt.active a {
	border-left: 1px solid #888888;
}

// flat, wyrównanie
.content-header {
	font-size: 28px;
	padding: 6px 10px 10px 10px;
}

.content-subheader {
	font-size: 18px;
	padding: 10px 10px 5px 10px;
	border-top: 1px solid $border-color;
}

.check_zgoda {
	line-height: 18px;
}

.flat-label {
	line-height: 18px;
	margin-left: 5px;
}

.row label.lab {
	display: block;
	float: right;
	font-weight: bold;
	margin: 4px 6px 0 0;
	font-size: 12px;
}

.deliveryCostToAccept label {float:left;display:inline-block;font-weight:bold;}

#questionnaire  ul label:not(.checkbox-css){text-align:left;width:400px;display:block;float: left;}

#toolPgr.tooltip-small .top-right-border{ position: absolute; top: 0px; bottom: 12px; width: 10px; right: -10px;}

#toolPgr.tooltip-small .top {padding: 13px 10px 6px 10px;}

label.lab{text-align:right;font-weight:bold;float:right;margin-right:5px;width:125px;}

.operator-logged #gridPreview{display:none;}
#gridPreview {margin-left: -17px;}
#gridPreview .top{height: 30px; @extend .flat-color3; @include border-no-bottom(#cacaca); margin-top: 10px;}
#gridPreview .middle{@extend .flat-color3; @include border-left-right(#cacaca); padding:0 20px;}
#gridPreview .bottom {
	@extend .flat-color3; @include border-no-top(#cacaca); height:29px; margin-bottom: 10px;

	&:before {
		content: "";
		position: absolute;
		left: 12px;
		bottom: 2px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 8px;
		border-color: #cccccc transparent transparent transparent;
	}

	&:after {
		content: "";
		position: absolute;
		left: 13px;
		bottom: 3px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 7px 0 7px;
		border-color: #ffffff transparent transparent transparent;
	}
}

.show-paper-invoice {
	color: #0c4da2; font-size: 10px; display: block; float: right;
	padding-right: 12px;

	&:after {
		content: "";
		position: relative;
		top: -1px;
		display: inline-block;
		margin-left: 5px;
		width: 5px;
		height: 5px;
		border-right: 2px solid #0c4da2;
		border-bottom: 2px solid #0c4da2;
		transform: rotate(45deg);
	}
}

span.arrow-raquo {
	&:after {
		content: "";
		position: relative;
		top: -1px;
		left: -6px;
		display: inline-block;
		width: 5px;
		height: 5px;
		border-right: 2px solid #0c4da2;
		border-top: 2px solid #0c4da2;
		transform: rotate(45deg)
	}
}

.arrow-laquo {
	&:before {
		content: "";
		position: relative;
		top: -1px;
		display: inline-block;
		margin-right: 5px;
		width: 5px;
		height: 5px;
		border-left: 2px solid #0c4da2;
		border-top: 2px solid #0c4da2;
		transform: rotate(-45deg)
	}
}

span.arrow-blue {
	position: relative;

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 2px;
		bottom: 0;
		margin: auto;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 2px 0 2px 3px;
		border-color: transparent transparent transparent #0c4da2;
	}
}

// zestaw

.set-item {
	transition: all 0.3s ease;
	opacity: 0.5;

	&.photoactive,
	&:hover {
		opacity: 1;
	}
}

.order_navigate.error .navigate-error {
	position: relative;
	text-align:right;
	height:55px;
	z-index: 0;

	.wstecz {
		z-index: 2;
	}

	&>* {
		position: relative;
		z-index: 1;

		&.error {
			z-index: 0;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 2px;
		right: 2px;
		bottom: 2px;
		left: 2px;
		background: #ff0000;
		opacity: 0.2;
	}
}

.icoPakiet {
	display:inline-block;
	height:18px;
	padding: 0 5px;
	line-height: 18px;
	background: #FFED00;
	border-radius: 4px;
	color: #164194;
	font-size: 10px;
	text-transform: uppercase;
	text-align: center;

	&:hover {
		text-decoration: none;
		color: #fff;
	}
}

.icoZestaw {
	display:inline-block;
	height:18px;
	padding: 0 5px;
	line-height: 18px;
	background: #ed1c24;
	border-radius: 4px;
	color: #fff;
	font-size: 10px;
	text-transform: uppercase;
	text-align: center;

	&:hover {
		text-decoration: none;
		color: #fff;
	}
}

.select3-container.deliverySelect, .select3-container.invoiceSelect {
	width: 351px;

	.select3-option {
		width: 335px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

#login-box .sprawdzDostepnosc{position:absolute;top:6px;left:311px;}
#login-box {
	#nick-status {position:absolute;top:5px;left:278px;}

	.statusError,
	.statusOk {
		#nick-status {
			position:absolute;
			top:10px;
			left:268px;
		}
	}
}

.main-page .content {
	margin-top: 10px;
	border-top: 1px solid $border-color;
	padding-top: 10px;
}

#multimedia li {  position: relative;top:1px;list-style:none; padding:0px; display:block; float:left; margin:8px 1px 0 !important; white-space:nowrap;
	@extend .flat-color2; @include border-no-bottom($border-color);
	font-weight:bold; text-align:center; display:block; float:left; height:30px;}
#multimedia li.current {height: 38px; margin: 0 1px !important; top:2px; @extend .flat-color3;}
#multimedia li a {text-align:center; color:#FFF; cursor:pointer;text-shadow:0 0 2px #111111;display:block; height:30px; padding: 0px 0px 0px 20px;}
#multimedia li span {color:#d3d3d3; font-size:13px; display:block; height: 23px; padding-top:7px; padding-right:20px;}
#multimedia .topTab{@include border-no-bottom($border-color); @extend .flat-color3; height:5px;}
#multimedia .bottomTab{@include border-no-top($border-color); @extend .flat-color3; height:9px;}

.bannerlisting_header{padding:0 0 10px 0;}


#left-column .grey .left-box-bar {position:relative;  height:32px; @extend .flat-color1;}
